import { createTheme } from "@mui/material";

// Light and Dark mode color palettes
const lightPalette = {
  primary: {
    main: '#1976d2',
  },
  background: {
    default: '#fafafa',
    paper: '#fff',
  },
  text: {
    primary: '#000',
    secondary: '#fff',
  },
};

const darkPalette = {
  primary: {
    main: '#bb86fc',
  },
  background: {
    default: '#121212',
    paper: '#1e1e1e',
  },
  text: {
    primary: '#fff',
    secondary: '#000',
  },
};

export const getTheme = (mode) => {
  return createTheme({
    palette: mode === 'dark' ? darkPalette : lightPalette,
  });
};

