import { Box, Grid, Link, Typography } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import React from 'react'

const NeedMoreHelp = ({onClickChat}) => {
    return (
        <Box className="need_more_help_section">
            <Box className="need_more_help_title">
                <Typography variant='h2'>
                    Need More Help?
                </Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                    <Link href='mailto:support@brang.com.au' className="need_more_help_box">
                        <Box className="need_more_help_box_icon">
                            <EmailIcon  />
                        </Box>
                        <Box className="need_more_help_box_text">
                            <Typography>Email</Typography>
                        </Box>
                    </Link>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                    <Box onClick={onClickChat} className="need_more_help_box">
                        <Box className="need_more_help_box_icon">
                            <QuestionAnswerRoundedIcon />
                        </Box>
                        <Box className="need_more_help_box_text">
                            <Typography>Chat</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                    <Link href="tel:1300 027 864" className="need_more_help_box">
                        <Box className="need_more_help_box_icon">
                            <CallRoundedIcon />
                        </Box>
                        <Box className="need_more_help_box_text">
                            <Typography>Call</Typography>
                        </Box>
                    </Link>
                </Grid>
            </Grid>
        </Box>
    )
}

export default NeedMoreHelp