import React, { createContext, useState, useEffect } from 'react';

// Create the context for the theme
const ThemeContext = createContext();

// Function to get the initial theme from localStorage or default to light mode
const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme : 'light'; // Default to 'light' if no theme is saved
};

const ThemeProvider = ({ children }) => {
    // State for theme
    const [theme, setTheme] = useState(getInitialTheme());

    // Update localStorage whenever the theme changes
    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);

    // Toggle function to switch between dark and light modes
    const toggleTheme = () => {
        setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export { ThemeContext, ThemeProvider };
