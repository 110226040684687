import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';

const TabTitle = (props) => {
    const { title } = props;

    return (
        <Box>
            <Helmet>
                <title>{`${title} - Brangchain`}</title>
                <link rel="icon" type="image/png" href="../assets/logos/sidebar-logo.png" />
            </Helmet>
        </Box>
    )
}

export default TabTitle;