import React from 'react';
import styled from '@emotion/styled';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Box, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import sideLogo from "../../assets/logos/sidebar-logo.png";
import { routes } from '../../routes/RouteWrapper';
import { selectCurrentUserObject } from '../../store/auth/authSlice';

const drawerWidth = 240;

// const routes = [
//     {
//         name: "Dashboard",
//         path: "/dashboard",
//         isAccess: ["ADMIN", "MERCHANT"]
//     },
//     {
//         name: "Brangers",
//         path: "/brangers",
//         isAccess: ["ADMIN"]
//     },
//     {
//         name: "Wallet",
//         path: "/wallet",
//         isAccess: ["MERCHANT"]
//     },
// ]


const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: 'rgb(43, 44, 64) !important',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 10px 0px',
    overflowX: 'hidden',
    minHeight: '100vh',

    [theme.breakpoints.up('xs')]: {
        position: 'fixed',
        zIndex: '9999',
    },

    [theme.breakpoints.up('lg')]: {
        position: 'fixed'
    },

});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'rgb(43, 44, 64) !important',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 10px 0px',
    overflowX: 'hidden',
    // width: `calc(${theme.spacing(7)} + 1px)`,
    width: `calc(90px + 1px)`,



    [theme.breakpoints.up('xs')]: {
        // width: `calc(${theme.spacing(8)} + 1px)`,
        left: '-200px',
        zIndex: '9999',
        position: 'fixed'
    },
    [theme.breakpoints.up('lg')]: {
        // width: `calc(${theme.spacing(8)} + 1px)`,
        left: '0px',
        zIndex: '9999',
        position: 'fixed'
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const DrawerHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const Sidebar = ({ open }) => {
    const theme = useTheme();
    const userType = useSelector(state => state.auth.userType)
    const location = useLocation();
    let user = useSelector(selectCurrentUserObject);

    return (
        <Drawer
            variant="permanent"
            open={open}
        >
            <DrawerHeader>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    gap: '10px',
                    marginTop: '15px',
                    '& img': {
                        width: '50px'
                    }
                }}>
                    <img src={sideLogo} />
                    <Typography sx={{
                        fontSize: "20px",
                        fontWeight: '500',
                        color: 'rgba(219, 219, 235, 0.87)',
                        display: open ? 'block' : "none"
                    }}>BRANG</Typography>
                </Box>
            </DrawerHeader>
            <List sx={{
                marginTop: '7px'
            }}>
                {routes?.map((route, index) => {
                    const { permissions, isMenu, icon } = route;
                    const shouldDisplay = isMenu && (permissions.includes(userType) || (permissions?.length === 0 && user.is_fredit));

                    if (!shouldDisplay) return null;

                    return (
                        <ListItem key={index} disablePadding
                            sx={{
                                display: 'block',
                                '& a': {
                                    textDecoration: 'none'
                                }
                            }}
                        >
                            <Link to={route.path} target={route.target}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        color: location.pathname.includes(route.path) ? "#fff" : "#dbdbeb99",
                                        backgroundColor: location.pathname.includes(route.path) ? "#696cff" : "#dbdbeb0a",
                                        borderRadius: '5px',
                                        margin: '10px 16px',
                                        '&:hover': {
                                            backgroundColor: '#696cff',
                                            color: '#fff',
                                            '& svg': {
                                                color: '#fff'
                                            }
                                        }
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: location.pathname.includes(route.path) ? "#fff" : "#dbdbeb99",
                                        }}
                                    >
                                        {icon}
                                    </ListItemIcon>
                                    <ListItemText primary={route.name} sx={{ height: open ? '100%' : '0px', opacity: open ? 1 : 0, whiteSpace: route.name?.length >= 20 ? 'normal' : "unset" }} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    );
                })}

            </List>
        </Drawer >
    );
};

export default Sidebar;
export { DrawerHeader }; // Exporting DrawerHeader component