import { Accordion, AccordionDetails, AccordionSummary, Box, Link, Pagination, Typography } from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const faqs = [
        {
            id: 1,
            question: "Will we have a link for Business’ to sign up?",
            answer: "Simply sign up by registering on our website and our customer support team will contact you, so you can start getting your items delivered to your customers' doorstep."
        },
        {
            id: 2,
            question: "What size parcels can I have delivered?",
            answer: "Max 60x60x60 cm & max 5 kg per item & must fit in the car or boot."
        },
        {
            id: 3,
            question: "I am not registered with BrangChain, how can I place an order?",
            answer: (
                <>
                    You can contact BrangChain via our website <Link sx={{ textDecoration: "none", color: "#696CFF" }} target="_blank" href="https://www.brangchain.com.au/">www.brangchain.com.au</Link> or via email at
                    <Link sx={{ textDecoration: "none", color: "#696CFF" }} target="_blank" href="mailto:contact@brang.com.au"> contact@brang.com.au </Link>
                    or by phoning us on <Link sx={{ textDecoration: "none", color: "#696CFF" }} target="_blank" href="tel:1300 027 864">13000BRANG </Link>
                    and we can then arrange for our Customer Experience team to contact you to set up an account.
                </>
            )
        },
        {
            id: 4,
            question: "How do I know if my information is secure?",
            answer: (
                <>
                    We know security and privacy are important to you – and they are important to us, too. Please see our Privacy Policy here:{" "}
                    <Link sx={{ textDecoration: "none", color: "#696CFF" }} target="_blank" href="https://www.brangchain.com.au/privacy-policy/">
                        https://www.brangchain.com.au/privacy-policy/
                    </Link>
                </>
            )
        },
        {
            id: 5,
            question: "Will I recieve confirmation when I place an order and when the order is delivered?",
            answer: "Yes, a confirmation email will be sent to you after you submit your order. Order delivery confirmation will also be emailed to you. You can see in real time via the Merchant Portal if your parcel is in process or has been delivered."
        },
        {
            id: 6,
            question: "Can I track my parcel?",
            answer: "Yes, the store and recipient will have access to live tracking if their mobile number and/or email is included in the delivery via our Merchant Portal."
        },
        {
            id: 7,
            question: "Does my parcel need to be packaged?",
            answer: "It is advised to carefully pack your delivery as BrangChain does not take any responsibility if there is any damage to the product due to not being securely packaged."
        },
        {
            id: 8,
            question: "What happens in case of a dispute about the refund?",
            answer: "The refund amount, if any, will take 7- 14 business days to be credited back and will be credited to the same account it was charged from."
        },
        {
            id: 9,
            question: "What happens if my parcel is lost/stolen or damaged?",
            answer: (
                <>
                    You can phone <Link sx={{ textDecoration: "none", color: "#696CFF" }} target="_blank" href="Tel:13000 27 26 4">
                        13000BRANG
                    </Link> or email {""}
                    <Link sx={{ textDecoration: "none", color: "#696CFF" }} target="_blank" href="mailto:support@brang.com.au">
                        support@brang.com.au
                    </Link> to contact our Support team. The situation will be investigated and the compensation if any, will take 7- 14 business days to be credited back and will be credited to the same account it was charged from.
                </>
            )
        },
        {
            id: 10,
            question: "What happens when no one is home to accept a delivery?",
            answer: "The Driver can either leave the parcel at a secure place suggested by the customer if instructed, or return it back to the store, at additional cost – subject to Driver availability and the distance that the Driver needs to travel to get the parcel back to the store. Note: When QR code scanning is required, packages are unable to be left unattended."
        },
        {
            id: 11,
            question: "What hours are you open and when do you make deliveries?",
            answer: "We are currently open from 7AM-8PM and make deliveries from 9AM-6PM Monday to Friday."
        },
        {
            id: 12,
            question: "Do you offer discounts?",
            answer: "We do have special discounts CODES generated by our marketing team occasionally. All available promos flash on our website and apps from time to time."
        },
        {
            id: 13,
            question: "Are there any restrictions on what we can deliver?",
            answer: "We are unable to deliver corrosive materials, dangerous chemicals or restricted or prohibited goods such firearms or explosive materials."
        },
        {
            id: 14,
            question: "Can I have alcohol or tobacco delivered?",
            answer: "Yes, however the business needs to ensure that the recipient who is placing an order is above 18 and the Branger has the right to check the ID on delivery, or the delivery can be refused."
        },
        {
            id: 15,
            question: "Do you offer medical courier services?",
            answer: "Yes, we do offer specialist Pharmacy delivery and medical courier services. BrangChain must comply with any legislative requirements. Compliance with the products being delivered is the sole responsibility of the store."
        },
        {
            id: 16,
            question: "Are you following Covid Protocols?",
            answer: "Yes, our Drivers are fully Covid Compliant. We take all necessary health and safety measures to ensure your delivery is handled safely and securely, practicing social distancing, and following appropriate hygiene protocols."
        },
        {
            id: 17,
            question: "Can you deliver from one city to another?  ",
            answer: "BrangChain are a local delivery service and cannot deliver from one city or state to another."
        },
        {
            id: 18,
            question: "What locations are you servicing?",
            answer: "BrangChain currently services the city of Melbourne and surrounding suburbs."
        },
        {
            id: 19,
            question: "Will I be provided with the Driver’s contact number?",
            answer: "Business customers will be provided with the Driver's number and the recipient will receive an Email or SMS with tracking of their order."
        },
        {
            id: 20,
            question: "If my delivery has not arrived, who do I contact?",
            answer: (
                <>
                    You can phone <Link sx={{ textDecoration: "none", color: "#696CFF" }} target="_blank" href="Tel:13000 27 26 4">
                        13000BRANG
                    </Link> or email {""}
                    <Link sx={{ textDecoration: "none", color: "#696CFF" }} target="_blank" href="mailto:support@brang.com.au">
                        support@brang.com.au
                    </Link> to contact our friendly and helpful Support team.
                </>
            )
        },
        {
            id: 21,
            question: "Can I reschedule or alter my delivery once it has already been booked?",
            answer: "Yes, you can reschedule a delivery with at least 4 hours notice at no extra charge. Rescheduling or amending a Delivery with less than 4 hours' notice, yet no less than 1hours notice, will incur a $20 reschedule fee. This will be subject to Driver’s availability."
        },
        {
            id: 22,
            question: "Can I book a Delivery with less than 4 hours’ notice?",
            answer: "Any delivery requiring less than 4 hours, notice, yet no less than 2 hours, notice, will incur a $20 express booking fee. This will be subject to driver availability."
        },
        {
            id: 23,
            question: "Are there any surcharges for deliveries on public holidays and hallmark holidays?",
            answer: "Public Holidays include a 15% surcharge and Hallmark Holidays such as Valentine’s Day, Mother’s & Father’s Day etc. include a 10% surcharge.Subject to Subscription level and driver availability."
        },
    ];

    // const [currentPage, setCurrentPage] = useState(1);
    // const faqsPerPage = 5;

    // // Calculate the number of pages
    // const pageCount = Math.ceil(faqs.length / faqsPerPage);

    // // Get current FAQs
    // const startIndex = (currentPage - 1) * faqsPerPage;
    // const currentFaqs = faqs.slice(startIndex, startIndex + faqsPerPage);

    // // Handle page change
    // const handlePageChange = (event, value) => {
    //     setExpanded(false);
    //     setCurrentPage(value);
    //     if (typeof window !== 'undefined') {
    //         window.scrollTo({
    //             top: 0,
    //             behavior: 'smooth'
    //         });
    //     }
    // };

    return (
        <Box className="faq_section_container">
            {faqs.map((faq, index) => (
                <Accordion expanded={expanded === index} sx={{
                    backgroundColor: "#232333",
                    boxShadow: "none",
                    marginBottom: "0px !important",
                    borderBottom: "1px solid #3A3A4B",
                    '&:before': {
                        display: 'none',
                    }
                }} onChange={handleChange(index)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{
                            margin:"18px 0 !important",
                            "& svg": {
                                fill: expanded === index ? "#fff" : "#fff"
                            },
                        }}
                    >
                        <Typography sx={{
                            color: expanded === index ? "#696CFF" : "#fff",
                            fontSize: { xl: "20px", lg: "20px", md: "20px", sm: "16px", xs: "16px" },
                            fontWeight: "400", "&:hover": { color: "#696CFF" },
                            textAlign: 'inherit',
                            marginRight: { xl: '0px', lg: '0px', md: '0px', sm: '10px', xs: '10px' }
                        }}>
                            {faq.question}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography sx={
                            {
                                color: expanded === index ? "#9797A9" : "#9797A9",
                                fontSize: { xl: "18px", lg: "18px", md: "18px", sm: "14px", xs: "14px" },
                            }
                        }>
                            {faq.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}

            {/* <Pagination sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#dbdbeb0a !important",
                padding: "10px",
                margin: "30px 0",
                borderRadius: "5px",
                "& .Mui-selected": {
                    backgroundColor: "#696CFF !important",
                    color: "#fff",
                },
                "& .MuiPaginationItem-page":{
                    color:"#fff !important",
                },
                "& .MuiPaginationItem-icon":{
                    fill:"#fff !important",
                }
            }}
                count={pageCount}
                page={currentPage}
                onChange={handlePageChange} /> */}
        </Box>
    )
}

export default FAQ