import React, { useEffect, useState } from 'react';
import LeftDrawer from './LeftDrawer';
import TrackingMap from './TrackingMap';
import Header from './Header';
import { useLazyGetPackageTrackingQuery } from '../../store/tasks/taskApiSlice';
import { useParams } from 'react-router-dom';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LottieLoader from '../loader/LottieLoader';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const TrackingLayout = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [getPackageTracking, { isLoading, data: trackingData, refetch }] = useLazyGetPackageTrackingQuery();
    const { hash } = useParams();
    const [responseMessage, setResponseMessage] = useState(null);
    const [multiBranger, setMultiBranger] = useState([]);
    const [points, setPoints] = useState([]);
    const [dropETA, setDropETA] = useState(null)
    const [isBrangerDetailsNull, setIsBrangerDetailsNull] = useState(false);
    const theme = useTheme();

    const handleToggle = () => {
        setIsDrawerOpen(!isDrawerOpen)
    };

    // Use MUI's breakpoints to detect screen size
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // max-width: 600px
    
    useEffect(()=> {
        if(isSmallScreen){
            setIsDrawerOpen(false)
        }
    },[isSmallScreen]);

    useEffect(() => {
        fetchMapData();
        // Set up interval to call the API every 60 seconds (60000 milliseconds)
        const intervalId = setInterval(fetchMapData, 60000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [])

    const fetchMapData = async () => {
        try {
            const response = await getPackageTracking(hash).unwrap();
            const { data, message } = response;

            if (message) {
                setResponseMessage(message)
            };

            if (data && data?.pickup) {

                // Specify the property you want to check (e.g., 'city')
                const propertyToCheck = 'branger_details';

                // Check if the specified property is null in at least one object
                const isBrangerNotAssigned = data?.packages.some(obj => obj[propertyToCheck] === null);

                setIsBrangerDetailsNull(data?.packages.some(obj => obj[propertyToCheck] === null));

                if (!isBrangerNotAssigned) {
                    let temp = data?.packages;
                    let pickup = data?.pickup;
                    let temp_array = [];
                    temp.map((item) => {
                        let branger_id = temp_array.find((obj) => item?.branger_details?.user === obj?.user)
                        if (branger_id === undefined) {
                            let temp_obj = {
                                branger_id: item?.branger_details?.id,
                                branger_dp: item?.branger_details?.dp,
                                user: item?.branger_details?.user,
                                branger_email: item?.branger_details?.branger_email,
                                branger_first_name: item?.branger_details?.branger_first_name,
                                branger_last_name: item?.branger_details?.branger_last_name,
                                branger_phone: item?.branger_details?.branger_phone,
                                pickup: { lat: Number(data?.pickup?.lat), lng: Number(data?.pickup?.long) },
                                lat: item?.branger_details.lat,
                                long: item?.branger_details.long,
                                business_name: item?.business_name,
                                customer_email: item?.customer_email,
                                customer_name: item?.customer_name,
                                customer_phone: item?.customer_phone,
                                task_status: item?.task_status,
                                drop_address: {
                                    address: item?.drop_address?.address,
                                },
                                package: [item],
                                pickup_address: data?.pickup.address
                            }
                            temp_array.push(temp_obj)
                        }
                        else {
                            branger_id.package.push(item)
                        }
                    })

                    if (pickup) {
                        setPoints([{ lat: Number(pickup?.lat), lng: Number(pickup?.long) }]);
                    }

                    if (temp?.length > 0) {
                        // Prepare the new points in an array first
                        const newPoints = temp.map((pack) => ({
                            lat: Number(pack?.drop_address?.lat),
                            lng: Number(pack?.drop_address?.long),
                            package: pack
                        }));

                        // Now set the state with all the new points at once
                        setPoints((prev) => [...prev, ...newPoints]); // Use the spread operator to add new points
                    }
                    setMultiBranger(temp_array);
                }
            }
            if (message) {
                setResponseMessage(message)
            }
        } catch (err) {
            if (err) {
                if (err?.data?.message) {
                    setResponseMessage(err?.data?.message)
                }
            }
        }
    };

    const handleETA = (eta) => {
        setDropETA(eta)
    }

    return (
        <div>
            {isLoading ? <Box><LottieLoader /></Box> :
                isBrangerDetailsNull ? <Box className="order_delivered_box">
                    <Box className="order_delivered_text">
                        <WarningAmberIcon />
                        <Typography>Driver not assigned in some task</Typography>
                    </Box>
                </Box> : responseMessage === "access denied!" ? <Box className="order_delivered_box">
                    <Box className="order_delivered_text">
                        <WarningAmberIcon />
                        <Typography>Data not found</Typography>
                    </Box>
                </Box> :
                    responseMessage === "order COMPLETED!" && !isBrangerDetailsNull ?
                        <Box className="order_delivered_box">
                            <Box className="order_delivered_text">
                                <CheckCircleOutlineIcon />
                                <Typography>Order delivered successfully</Typography>
                            </Box>
                        </Box> :
                        <>
                            <Header handleToggle={handleToggle} isDrawerOpen={isDrawerOpen} />
                            <LeftDrawer refetchData={fetchMapData} dropETA={dropETA} multiBranger={multiBranger} isDrawerOpen={isDrawerOpen} handleToggle={handleToggle} />
                            <TrackingMap handleETA={handleETA} points={points} multiBranger={multiBranger} />
                        </>
            }
        </div >
    )
};

export default TrackingLayout;
