import React, { useEffect, useRef, useState } from 'react'
import CustomTabs from '../../theme/style-components/CustomTabs';
import { Box, Button, CircularProgress, Grid, IconButton, LinearProgress, List, ListItem, Menu, MenuItem, Paper, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CustomChips from '../../theme/style-components/CustomChips';
import CustomButton from '../../theme/style-components/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import CustomDateRangePicker from '../../theme/style-components/CustomDateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteAttachments, selectAttachments, setAttachments } from '../../store/attachments/attachmentsSlice';
import { useAddTaskAttachmentsMutation, useDeleteAttachmentMutation, useGetAssignedTasksQuery, useGetBrangerTasksQuery, useGetCompletedTasksQuery, useGetUnAssignedTasksQuery, useLazyGetBrangerLastLocQuery, useLazyGetOptimizePathQuery, useLazyGetTaskAttachmentsQuery, } from '../../store/tasks/taskApiSlice';
import axios from 'axios';
import { useTheme } from '@emotion/react';
import { useGetBrangersQuery, useGetBusyBrangersQuery, useGetFreeBrangersQuery, useGetInactiveBrangersQuery, useLazyGetBrangerByIdQuery, useLazyGetBrangersTaskSortListQuery, usePutBrangerTaskSortMutation } from '../../store/brangers/brangerApiSlice';
import moment from 'moment';
import { selectTasksDate } from '../../store/map/mapSlice';
import { useLazyGetUserProfileQuery } from '../../store/users/usersApiSlice';
import CustomInput from '../../theme/style-components/CustomInput';
import LottieLoader from '../loader/LottieLoader';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import profileimg from '../../assets/dashboard-image/profile-img.png';
import DeleteIcon from '@mui/icons-material/Delete';
import AddAttachment from './AddAttachment';
import CustomModal from '../../theme/style-components/CustomModal';
import AssignBranger from './AssignBranger';
import ResetDelivery from './ResetDelivery';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DraggableList from './DraggableList ';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import TempMap from './TempMap';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ErrorIcon from '@mui/icons-material/Error';
import { db } from "../../firebase/firebaseConfig";
import { onValue, ref } from "firebase/database";

const NewMap = (props) => {
    const orderTab = [
        { label: 'Unassigned' },
        { label: 'Assigned' },
        { label: 'Completed' },
    ];

    const innerOrderTab = [
        { label: 'Details' },
        { label: 'Attachments' },
        { label: 'History' },
    ];

    const brangerTab = [
        { label: 'Free' },
        { label: 'Busy' },
        { label: 'Inactive' },
    ];

    const tasksTab = [
        { label: 'Details' },
        { label: 'Customer' },
        { label: 'History' },
    ];

    const [activeOrderTab, setActiveOrderTab] = useState(0);
    const [activeInnerOrderTab, setActiveInnerOrderTab] = useState(0);
    const [activeTasksTab, setActiveTasksTab] = useState(0);
    const [activeBrangerTab, setActiveBrangerTab] = useState(0);
    const [isAssignBrangerModal, setIsAssignBrangerModalOpen] = useState(false);
    const [isBrangerDetailsOpen, setIsBrangerDetailsOpen] = useState(false);


    const handleDateRangeChange = (dates) => {
        setDateRange({
            startDate: dates[0],
            endDate: dates[1],
        });
    };


    const { loggedAdmin, open } = props;
    const [taskSearch, setTaskSearch] = useState("");
    const [brangerSearch, setBrangerSearch] = useState("");
    const [getUserProfile] = useLazyGetUserProfileQuery();
    const [getOptimizePath] = useLazyGetOptimizePathQuery();
    const navigate = useNavigate();
    const [clearMap, setClearMap] = useState(false);

    //redux logic
    let tasksDate = useSelector(selectTasksDate);

    useEffect(() => {
        const getProfile = async () => {
            try {
                await getUserProfile()
                    .unwrap()
                    .then((res) => {
                        if (res.data.user_type === "MERCHANT") {
                            navigate("/dashboard", { replace: true });
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        }

        getProfile()
    }, []);

    //state variables
    const [dateRange, setDateRange] = useState({});
    const [currentLat, setCurrentLat] = useState(28.70406);
    const [currentLong, setCurrentLong] = useState(77.102493);
    const [showAssignBrangerDialog, setShowAssignBrangerDialog] = useState(false);
    const [showResetTask, setShowResetTask] = useState(false);
    const [currentTask, setCurrentTask] = useState(null);
    const [markers, setMarkers] = useState(null);
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [isSummaryOpen, setIsSummaryOpen] = useState(false);
    const [isTaskSummaryOpen, setIsTaskSummaryOpen] = useState(false);
    const [currentTaskToShow, setCurrentTaskToShow] = useState([{}]);
    const [isBrangerSummaryOpen, setIsBrangerSummaryOpen] = useState(false);
    const [branger, setBranger] = useState(false);
    const [selectedBranger, setSelectedBranger] = useState({});
    const [orderID, setOrderID] = useState(0);
    const [isOrder, setIsOrder] = useState(false);
    const [taskID, setTaskID] = useState(0);
    const [order, setOrder] = useState(true);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [assignTask, setAssignTask] = useState(false);
    const [unassignedPage, setUnassignedPage] = useState(1);
    const [assignedPage, setAssignedPage] = useState(1);
    const [completedPage, setCompletedPage] = useState(1);
    const [freePage, setFreePage] = useState(1);
    const [busyPage, setBusyPage] = useState(1);
    const [inactivePage, setInactivePage] = useState(1);
    const [isBrangerTaskSummaryOpen, setIsBrangerTaskSummaryOpen] =
        useState(false);
    const [currentBrangerTaskToShow, setCurrentBrangerTaskToShow] = useState({});
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const [brangerLiveLocation, setBrangerLiveLocation] = useState([]);

    useEffect(() => {
        setDateRange({
            startDate: new Date(),
            endDate: new Date()
        });
    }, [])

    //base query hooks
    const {
        isLoading: areTasksLoading,
        data: tasks,
        isSuccess,
        isError,
        error: getTaskError,
        refetch: refetchTasks,
    } = useGetUnAssignedTasksQuery({
        taskSearch: taskSearch,
        startDate: dateRange?.startDate && dateRange?.endDate
            ? moment(dateRange?.startDate).format("YYYY-MM-DD")
            : "",
        endDate: dateRange?.startDate && dateRange?.endDate
            ? moment(dateRange?.endDate).format("YYYY-MM-DD")
            : "",
        page: unassignedPage,
    });

    const {
        isLoading: areAssignedTasksLoading,
        data: assignedTasks,
        isSuccess: isAssignedTaskSuccess,
        isError: isAssignedTaskError,
        error: assignedTaskError,
        refetch: refetchAssignedTasks
    } = useGetAssignedTasksQuery({
        taskSearch: taskSearch,
        startDate: dateRange?.startDate && dateRange?.endDate
            ? moment(dateRange?.startDate).format("YYYY-MM-DD")
            : "",
        endDate: dateRange?.startDate && dateRange?.endDate
            ? moment(dateRange?.endDate).format("YYYY-MM-DD")
            : "",
        page: assignedPage,
        task_status_arr: ["ASSIGNED", "STARTED", "PICKEDUP"],
    });
    const {
        isLoading: areCompletedTasksLoading,
        data: completedTasks,
        isSuccess: isCompletedTaskSuccess,
        isError: isCompletedTaskError,
        error: completedTaskError,
        refetch: refetchCompletedTasks
    } = useGetCompletedTasksQuery({
        taskSearch: taskSearch,
        startDate: dateRange?.startDate && dateRange?.endDate
            ? moment(dateRange?.startDate).format("YYYY-MM-DD")
            : "",
        endDate: dateRange?.startDate && dateRange?.endDate
            ? moment(dateRange?.endDate).format("YYYY-MM-DD")
            : "",
        page: completedPage,
        task_status_arr: ["CANCELED", "FAILED", "DELIVERED"],
    });

    const {
        isLoading: areBrangersLoading,
        data: brangersReturned,
        isSuccess: isBrangerReturnSuccess,
        isError: isBrangerReturnError,
        error: brangerReturnError,
    } = useGetFreeBrangersQuery({
        userSearch: brangerSearch,
        page: freePage,
    });

    const {
        isLoading: areBusyBrangersLoading,
        data: busyBrangersReturned,
        isSuccess: isBusyBrangerReturnSuccess,
        isError: isBusyBrangerReturnError,
        error: busyBrangerReturnError,
    } = useGetBusyBrangersQuery({
        userSearch: brangerSearch,
        page: busyPage,
    });

    const [getBrangerById, { isLoading: isBrangerLoading }] = useLazyGetBrangerByIdQuery();

    const {
        isLoading: areInactiveBrangersLoading,
        data: inactiveBrangersReturned,
        isSuccess: isInactiveBrangerReturnSuccess,
        isError: isInactiveBrangerReturnError,
        error: inactiveBrangerReturnError,
    } = useGetInactiveBrangersQuery({
        userSearch: brangerSearch,
        page: inactivePage,
    });

    const {
        isLoading: areBrangerTasksLoading,
        data: brangerTasksReturned,
        isSuccess: isBrangerTaskSuccess,
        isError: isBrangerTaskError,
        error: brangerTaskError,
    } = useGetBrangerTasksQuery({
        brangerId: selectedBranger?.id ?? null,
        task_status_arr: ["ASSIGNED", "STARTED", "PICKEDUP"],
    });

    const [
        getBrangerLastLoc,
        {
            isLoading: brangerLastLocLoading,
            data: brangerLastLocReturned,
            isSuccess: isBrangerLastLocSuccess,
            isError: isBrangerLastLocError,
            error: brangerLastLocError,
        },
    ] = useLazyGetBrangerLastLocQuery();

    const [
        getBrangersTaskSortList, {
            isLoading: brangerTaskSortLoading,
            data: brangerTaskSortReturned,
            isSuccess: isBrangerTaskSortSuccess,
            isError: isBrangerTaskSortError,
            error: brangerTaskSortError,
            isFetching: isbrangerTaskSortFecthing
        }] = useLazyGetBrangersTaskSortListQuery();

    const [
        getTaskAttachments,
        {
            isLoading: isAttachmentLoading,
            data: attachmentData,
            isSuccess: isAttachmentSuccess,
            isError: isAttachmentError,
            error: attachmentError,
        },
    ] = useLazyGetTaskAttachmentsQuery();

    const [deleteAttachment] = useDeleteAttachmentMutation();

    const emptyOrder = {
        name: "",
        city: "",
        type: "",
        minDistance: "",
        maxDistance: "",
        price: "",
        status: 0,
    };

    const vehicleType = ["Car", "Van"];

    const {
        isLoading,
        loggedUser,
        getBranger,
        getOrder,
        orders,
        brangers,
        updateOrder,
        isSaving,
        adminDashboard,
        dashboard,
    } = props;
    const [value, setValue] = useState(0);
    const [selected, setSelected] = useState(emptyOrder);
    const [Open, setOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [brangerFilterOpen, setBrangerFilterOpen] = useState(false);
    const [selectedTabName, setSelectedtabName] = useState("UNASSIGNED");
    const [orderValue, setOrderValue] = useState(0);
    const [selectedOrderTabName, setSelectedOrderTabName] = useState("");
    const [taskValue, setTaskValue] = useState(0);
    const [selectedTaskTabName, setSelectedTaskTabName] = useState("");
    const [brangerTabValue, setBrangerTabValue] = useState(0);
    const [brangerDetailsTabValue, setBrangerDetailsTabValue] = useState(0);
    const [brangerTaskValue, setBrangerTaskValue] = useState(0);
    const [currentAttachments, setCurrentAttachments] = useState([]);
    const [freeBusyBranger, setFreeBusyBranger] = useState([]);
    const [orderTabs, setOrderTabs] = useState(true);
    const [tasksTabs, setTasksTabs] = useState(false);
    const [taskDetailsTabs, setTaskDetailsTabs] = useState(false);
    const [selectedTask, setSelectedTask] = useState({});
    const [anchorEl, setAnchorEl] = useState(false);
    const [anchorElBranger, setAnchorElBranger] = useState(false);
    const isMenuOpen = anchorEl;
    const isBrangerFilterOpen = anchorElBranger;
    const [brangerCurrentLocation, setBrangerCurrentLocation] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedtabName("UNASSIGNED");
        setSelectedOrderTabName("DETAILS");
    }, []);

    const handleChange = (event, newValue) => {
        const selectedTabName = event.currentTarget.getAttribute("data-tab-name");
        setSelectedtabName(selectedTabName);
        setValue(newValue);
    };

    const handleTaskChange = (event, newValue) => {
        const selectedTaskTab = event.currentTarget.getAttribute("data-tab-name");
        setSelectedTaskTabName(selectedTaskTab);
        setTaskValue(newValue);
    };

    const handleBrangerTaskChange = (event, newValue) => {
        const selectedTaskTab = event.currentTarget.getAttribute("data-tab-name");
        // setSelectedTaskTabName(selectedTaskTab)
        setBrangerTaskValue(newValue);
    };

    const handleOrderChange = (event, newValue) => {
        const OrderTabName = event.currentTarget.getAttribute("data-tab-name");
        setSelectedOrderTabName(OrderTabName);
        setOrderValue(newValue);
    };

    const handleBrangerChange = (event, newValue) => {
        setBrangerTabValue(newValue);
    };

    const handleBrangerDetailsChange = (event, newValue) => {
        setBrangerDetailsTabValue(newValue);
    };

    const [val, setVal] = useState(0);
    const handleChangeE = (event, newValue) => {
        setVal(newValue);
    };

    const theme = useTheme();
    // const [open, setOpen] = useState(true);
    const [openB, setOpenB] = useState(false);
    const [opend, setOpenD] = useState(true);

    // useEffect(() => {
    //   alert('markers changed');
    // }, [markers]);

    useEffect(() => {
        if (isSaving && !isLoading) {
            getOrder();
        }
    }, [isSaving]);

    useEffect(() => {
        if (orders && !isLoading) {
        }
    }, [orders]);

    const mapRef = useRef();
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(3);

    // const url =
    //   "https://data.police.uk/api/crimes-street/all-crime?lat=52.629729&lng=-1.131592&date=2019-10";
    // const { data, error } = useSwr(url, { fetcher });
    // const crimes = !areTasksLoading && tasks.data ? tasks.data : [];
    // const points = crimes.map((crime) => ({
    //   type: "Feature",
    //   properties: { cluster: false, crimeId: 1, category: "foo" },
    //   geometry: {
    //     type: "Point",
    //     coordinates: [parseFloat("-37.813629"), parseFloat("144.963058")],
    //   },
    // }));

    //methods

    // const { clusters, supercluster } = useSupercluster({
    //   points,
    //   bounds,
    //   zoom,
    //   options: { radius: 150, maxZoom: 10 },
    // });

    // const handleDrawerLeft = () => {
    //   setOpenD(!opend);
    // };
    // const handleDrawerRight = () => {
    //   setOpen(!open);
    // };
    const attachments = useSelector(selectAttachments);

    useEffect(() => {
        if (selectedOrder) {
            getTaskAttachments(selectedOrder?.id)
                .unwrap()
                .then((res) => {
                    if (res) {
                        dispatch(setAttachments(res?.data));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [selectedOrder]);

    const handleAssignBranger = () => {
        //handle assign branger
    };

    const handleMapChange = (task, order) => {
        console.log(task, "task");

        let pointsArray = [];

        if (order?.id) {
            pointsArray.push({
                type: "pickup",
                lat: Number(order.pickup_address_details.lat),
                long: Number(order.pickup_address_details.long),
            });
            pointsArray.push({
                type: "drop",
                lat: Number(task.drop_address.lat),
                long: Number(task.drop_address.long),
            });
        } else {
            pointsArray.push({
                type: "pickup",
                lat: Number(task.pickup_address_details.lat),
                long: Number(task.pickup_address_details.long),
            });
            task.tasks.forEach((job) => {
                pointsArray.push({
                    type: "drop",
                    lat: Number(job.drop_address.lat),
                    long: Number(job.drop_address.long),
                });
            });
        }
        setMarkers(pointsArray);
    };

    const [brangerLiveAddress, setBrangerLiveAddress] = useState();
    const [currentBrangerLocationData, setCurrentBrangerLocationData] = useState();
    const fetchAddress = async (latitude, longitude) => {
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDp8kshAwl2VeZe4lSseBVFhYVNQpVXyt0`
            );

            if (response.data.results.length > 0) {
                setBrangerLiveAddress(response.data.results[0].formatted_address);
            }
        } catch (error) {
            console.error("Error fetching address:", error);
        }
    };

    const handleBrangerLastLoc = async () => {

        console.log(brangerLiveLocation, "brangerLiveLocation");

        if (selectedBranger?.id) {
            const brangerCurrentLoc = brangerLiveLocation.filter((item) => item?.userId === selectedBranger?.id)?.[0] ?? []
            if (brangerCurrentLoc) {
                fetchAddress(brangerCurrentLoc?.latitude, brangerCurrentLoc?.longitude);
                setBrangerCurrentLocation({
                    lat: brangerCurrentLoc?.latitude,
                    lng: brangerCurrentLoc?.longitude,
                    id: Number(selectedBranger?.id ?? null),
                });
                const pointsArray1 = [];
                pointsArray1.push({
                    type: "branger",
                    lat: Number(brangerCurrentLoc?.latitude),
                    long: Number(brangerCurrentLoc?.longitude),
                });
                setMarkers(pointsArray1);
            }
        }


        // const lastLocData = await getBrangerLastLoc({
        //     brangerId: selectedBranger?.id ?? null,
        // })
        //     .unwrap()
        //     .then((res) => {
        //         if (res.data) {
        //             const pointsArray1 = [];
        //             if (res.data.current.lat && res.data.current.long) {
        //                 setCurrentBrangerLocationData(res.data);
        //                 fetchAddress(res.data.current.lat, res.data.current.long);
        //                 setBrangerCurrentLocation({
        //                     lat: res.data.current.lat,
        //                     lng: res.data.current.long,
        //                     id: Number(selectedBranger?.id ?? null),
        //                 });
        //             }
        //             pointsArray1.push({
        //                 type: "branger",
        //                 lat: Number(res.data.current.lat),
        //                 long: Number(res.data.current.long),
        //             });
        //             setMarkers(pointsArray1);
        //         }
        //     })
        //     .catch((err) => {
        //         if (err) {
        //             toast.error(err.data.message, {
        //                 position: toast.POSITION.TOP_RIGHT,
        //             });
        //         }
        //     });
    };

    const handleDateChange = (date) => {
        setDateRange(date);
        setOpenDatePicker(!openDatePicker);
    };

    const callGetOptimizePath = async (id) => {
        const pathData = await getOptimizePath({ order_id: id, force: false })
            .unwrap()
            .then((res) => {
                if (res) {
                    if (res.message === "already optimized hence, no changes!") {
                        toast.success("already optimized hence, no changes!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                    if (res.message === "successfully optimized") {
                        toast.success("successfully optimized!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        window.location.reload();
                    }
                }
            })
            .catch((err) => {
                console.log(err, "errr");
            });
    };

    const handleDeleteAttachments = async (attaId) => {
        const response = deleteAttachment(attaId)
            .unwrap()
            .then((res) => {
                if (res) {
                    dispatch(deleteAttachments(attaId));
                    toast.success("Attachment deleted successfully.", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((err) => {
                console.log(err, "err");
            });
    };
    const renderStatusTypography = (status, color) => {
        const statusCount = order?.tasks?.filter(
            (item) => item.task_status === status
        )?.length;
        return (
            <Typography variant="body1" style={{ margin: "0px !important", color }}>
                {statusCount > 0 &&
                    `${status.charAt(0).toUpperCase() + status.slice(1)
                    } (${statusCount}/${order?.tasks?.length})`}
            </Typography>
        );
    };

    useEffect(() => {
        const brangerIdArray = [];
        if (brangersReturned?.data?.length) {
            brangersReturned?.data?.forEach((branger) => {
                brangerIdArray.push(branger);
            });
        }
        if (busyBrangersReturned?.data?.length) {
            busyBrangersReturned?.data?.forEach((branger) => {
                brangerIdArray.push(branger);
            });
        }
        if (brangerIdArray?.length) {
            setFreeBusyBranger(brangerIdArray)
        }
    }, [brangersReturned, busyBrangersReturned]);

    const [allDeliveries, setAllDeliveries] = useState([])

    useEffect(() => {
        const allDelivery = []
        // if(assignedTasks && tasks && completedTasks){
        //   if(selectedTabName === "ASSIGNED"){
        //
        //   }if(selectedTabName === "UNASSIGNED"){
        //
        //   }if(selectedTabName === "COMPLETED"){
        //
        //   }
        // }
        if (assignedTasks?.data && tasks?.data && completedTasks?.data) {
            assignedTasks?.data?.forEach((task) => {
                allDelivery.push(task)
            });
            tasks?.data?.forEach((task) => {
                allDelivery.push(task)
            });
            completedTasks?.data?.forEach((task) => {
                allDelivery.push(task)
            });
        }
        setAllDeliveries(allDelivery)
    }, [assignedTasks, tasks, completedTasks, selectedTabName])

    //base query hooks
    const [addTaskAttachments, { isLoading: isAddAttachmentLoading }] = useAddTaskAttachmentsMutation();
    const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);


    const refetchGetTaskAttachment = () => {
        getTaskAttachments(selectedOrder?.id)
            .unwrap()
            .then((res) => {
                if (res) {
                    dispatch(setAttachments(res?.data));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleAddAttachmentSubmit = async (formValue) => {
        let formData = new FormData();
        formData.append("order_id", selectedOrder?.id);
        if (formValue.comment) {
            formData.append("comment", formValue.comment);
        };
        formData.append("attachment", formValue.attachment_file);

        try {
            const response = await addTaskAttachments(formData).unwrap();
            if (response?.data) {
                toast.success("Attachment uploaded successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                const refetch = refetchGetTaskAttachment();
                setIsAttachmentOpen(false);
            }
        } catch (err) {
            if (err) {
                toast.error(err.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const handleRefetch = () => {
        refetchTasks();
        refetchAssignedTasks();
        refetchCompletedTasks();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handeBrangerFilterSearch = () => {
        setAnchorElBranger(null);
    };

    const handleFilterMenu = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleBrangerFilterMenu = (e) => {
        setAnchorElBranger(e.currentTarget)
    }

    const statusStyles = {
        ASSIGNED: { color: "#696cff" },
        STARTED: { color: "#67c932" },
        PICKEDUP: { color: "#ffab00" }
    };

    const [isOrderSlideOpen, setIsOrderSlideOpen] = useState(true);
    const [isBrangerSlideOpen, setIsBrangerSlideOpen] = useState(true);
    const [mapWidth, setMapWidth] = useState(`calc(100% - 800px)`);

    useEffect(() => {
        if (!isOrderSlideOpen) {
            setMapWidth(`calc(100% - 400px)`)
        }

        if (!isOrderSlideOpen && !isBrangerSlideOpen) {
            setMapWidth(`calc(100% - 0px)`)
        }

        if (!isOrderSlideOpen && isBrangerSlideOpen) {
            setMapWidth(`calc(100% - 400px)`)
        }

        if (isOrderSlideOpen && !isBrangerSlideOpen) {
            setMapWidth(`calc(100% - 400px)`)
        }

    }, [isOrderSlideOpen, isBrangerSlideOpen])

    const handleOrderSlide = () => {
        setIsOrderSlideOpen(!isOrderSlideOpen)
    };

    const handleBrangerSlide = () => {
        setIsBrangerSlideOpen(!isBrangerSlideOpen)
    }

    const [brangerTaskSortList, setBrangerTaskSortList] = useState([]);

    // Function to generate a random color
    function getRandomColor() {
        var r = Math.floor(Math.random() * 128);
        var g = Math.floor(Math.random() * 128);
        var b = Math.floor(Math.random() * 128);
        return `rgb(${r}, ${g}, ${b})`;
    }

    // Create an object to store colors based on order_id
    var colorMap = {};

    const fetchBrangerTaskSortList = async (id) => {
        try {
            const brangerTaskSortResponse = await getBrangersTaskSortList({ branger_id: id, date: moment(new Date()).format("YYYY-MM-DD") }).unwrap().then((res) => {
                if (res) {
                    // setBrangerTaskSortList(res?.data);
                    const brangerTaskList = res?.data.map(function (item) {
                        var order_id = item.order_id;
                        if (!colorMap[order_id]) {
                            colorMap[order_id] = getRandomColor();
                        }
                        return { ...item, color_key: colorMap[order_id] };
                    });
                    setBrangerTaskSortList(brangerTaskList);
                }
            })
        } catch (err) {
            if (err) {
                toast.error(err.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = list.slice();
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const [putBrangerTaskSort, { isPutBrangerTaskSortLoading }] = usePutBrangerTaskSortMutation();

    const sortBrangerTask = async (idArray, brangerID) => {
        try {
            const sortResponse = putBrangerTaskSort({
                grp_ids: idArray, branger_id: brangerID
            }).unwrap();

        } catch (err) {
            if (err) {
                toast.error(err.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    }

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        if (result.destination.droppableId === result.source.droppableId && result.destination.index === result.source.index) {
            console.log("they're equal");
            return;
        } else {
            const items = reorder(
                brangerTaskSortList,
                result.source.index,
                result.destination.index
            );

            const idArray = items.map((item) => item.id);

            setBrangerTaskSortList(items);
            if (result.draggableId && idArray) {
                sortBrangerTask(idArray, selectedBranger?.id ?? null);
            }
        }
    };

    const openBrangerDetails = async (id) => {
        setIsBrangerDetailsOpen(true);
        if (id) {
            try {
                // Trigger the query
                const response = await getBrangerById({ id: id });
                fetchBrangerTaskSortList(id);
                if (response) {
                    setSelectedBranger(response?.data?.data?.[0]);
                }
                return response?.data?.data?.[0];
            } catch (error) {
                console.error("Error occurred while fetching Branger:", error);
            }
        }
    };

    console.log(selectedBranger, "selectedBranger");
    console.log(markers, "markers");

    const fetchBrangerById = async (id) => {
        if (id) {
            try {
                const response = await getBrangerById({ id: id });
                return response?.data?.data?.[0];
            } catch (error) {
                toast.error("Error occurred while fetching Branger:")
            }
        }
    }

    const orderStatusMappings = {
        ADDING_PACKAGES: { label: "Draft", color: "info" },
        CREATED: { label: "Draft", color: "info" },
        PROCESSING_PAYMENT: { label: "Payment Pending", color: "warning" },
        PAYMENT_FAILED: { label: "Payment failed", color: "error" },
        SCHEDULED: { label: "Scheduled", color: "primary" },
        COMPLETED: { label: "Completed", color: "success" },
    };

    const getStatusCount = (dataStatus, status, color) => {
        const count = dataStatus === status ? 1 : 0
        return count > 0 ? (
            <CustomChips
                color={color}
                labelColor={color}
                label={`${status.charAt(0) + status.toLowerCase().slice(1)}`} />
        ) : null;
    };

    const renderStatusCounts = (dataStatus) => {
        let hasValue = false;

        const checkAndRender = (status, color) => {
            const result = getStatusCount(dataStatus, status, color);
            if (result) {
                hasValue = true;
                return result;
            }
            return null;
        };

        return (
            <>
                {checkAndRender("ASSIGNED", "primary")}
                {checkAndRender("UNASSIGNED", "warning")}
                {checkAndRender("STARTED", "info")}
                {checkAndRender("PICKEDUP", "info")}
                {checkAndRender("DELIVERED", "success")}
                {checkAndRender("FAILED", "error")}
                {checkAndRender("CANCELED", "error")}
                {checkAndRender("DELETED", "error")}
                {!hasValue && <Typography variant="body2" style={{ margin: "0 !important" }}>N/A</Typography>}
            </>
        );
    };

    useEffect(() => {
        let data = {}
        const query = ref(db, process.env.REACT_APP_FIREBASE_COLLECTION);
        return onValue(query, (snapshot) => {
            data = snapshot.val();
            const dataArray = Object.values(data);
            setBrangerLiveLocation(dataArray);
        });
    }, []);

    return (
        <>
            <Box sx={{
                // display: "flex",
                // alignItems: 'center'
            }}>
                <Box sx={{
                    position: 'relative',
                    display: 'flex',
                }}>

                    <Box sx={{
                        width: isOrderSlideOpen ? '400px' : "0px",
                        flex: isOrderSlideOpen ? '0 0 400px' : "inherit",
                        position: 'relative',
                        transition: 'width 400ms ease, flex 300ms ease',
                        display: 'flex',
                    }}>
                        <Box sx={{
                            backgroundColor: '#2b2c40',
                            overflowY: 'auto',
                            height: '100vh',
                            width: '100%',
                            zIndex: '99',
                            transition: 'all 0.3s',
                        }}>
                            {orderTabs &&
                                <Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "16px 16px", color: "#dbdbeb99" }}>
                                        <Box><Typography>ORDERS</Typography> </Box>
                                        <Box><CustomButton buttonName="Clear Map" color="primary" onClick={() => {
                                            setClearMap(true);
                                        }} /></Box>
                                        <Box><FilterAltIcon onClick={(e) => handleFilterMenu(e)} cursor="pointer" /></Box>
                                    </Box>
                                    <CustomTabs
                                        isBacis={true}
                                        activeTab={activeOrderTab}
                                        onChange={(e, value) => setActiveOrderTab(value)} tabsData={orderTab}>
                                        {/* tab 1 */}
                                        {!areTasksLoading && isSuccess
                                            ? tasks.data.length > 0
                                                ? <> {tasks.data.map((order) => (
                                                    <>
                                                        <Box sx={{
                                                            color: '#dbdbeb',
                                                            padding: '15px',
                                                            paddingTop: '0px',
                                                        }}>
                                                            <Box
                                                                onClick={() => {
                                                                    setTasksTabs(true);
                                                                    setOrderTabs(false);
                                                                    setSelectedOrder(order);
                                                                    handleMapChange(order);
                                                                }} sx={{
                                                                    cursor: "pointer",
                                                                    display: "flex",
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    marginBottom: '15px',
                                                                    marginTop: '15px',
                                                                }}>
                                                                <Box sx={{
                                                                    display: "flex",
                                                                    alignItems: 'center',
                                                                    gap: '10px',
                                                                }}>
                                                                    <Box sx={{
                                                                        '& img': {
                                                                            width: '35px',
                                                                            height: '35px',
                                                                            borderRadius: '100%'
                                                                        }
                                                                    }}>
                                                                        <img src={profileimg} />
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography>Order ID: #{order.id}</Typography>
                                                                        <Typography>{order?.merchant_first_name}</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ textAlign: "end" }}>
                                                                    <Typography>
                                                                        {moment.utc(order.scheduled_dt).format("DD/MM/YYYY h:mm")}
                                                                    </Typography>
                                                                    {/* <Typography>{order.order_status}</Typography> */}
                                                                    <CustomChips label={orderStatusMappings[order.order_status]?.label || "N/A"}
                                                                        color={orderStatusMappings[order.order_status]?.color || "info"}
                                                                        labelColor={orderStatusMappings[order.order_status]?.color || "info"} />
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{
                                                                borderBottom: '1px solid #dbdbeb99',
                                                                display: 'flex',
                                                            }}>

                                                                <Box sx={{
                                                                    display: 'inline-flex',
                                                                    flexDirection: 'column'
                                                                }}>
                                                                    <Box sx={{
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        lineHeight: '20px',
                                                                        backgroundColor: '#1394ff',
                                                                        borderRadius: '100%',
                                                                        textAlign: 'center',
                                                                        fontSize: '12px'
                                                                    }}>p</Box>
                                                                    {order.tasks.length > 0 &&
                                                                        order.tasks.map(() => {
                                                                            return (
                                                                                <><Box sx={{
                                                                                    borderLeft: "1px solid #1394ff",
                                                                                    height: '37px',
                                                                                    marginLeft: '9px'
                                                                                }}></Box>
                                                                                    <Box sx={{
                                                                                        width: '20px',
                                                                                        height: '20px',
                                                                                        lineHeight: '20px',
                                                                                        border: "1px solid #1394ff",
                                                                                        borderRadius: '100%',
                                                                                        textAlign: 'center',
                                                                                        fontSize: '12px'

                                                                                    }}>D</Box></>
                                                                            )
                                                                        })
                                                                    }
                                                                </Box>
                                                                <Box sx={{
                                                                    maxWidth: '-webkit-fill-available',
                                                                    width: '100%',
                                                                    padding: '0px 0px 10px 15px',
                                                                }}>

                                                                    <Box sx={{
                                                                        marginBottom: '15px',
                                                                    }}>
                                                                        <Typography sx={{
                                                                            color: "#dbdbeb99",
                                                                            fontSize: '14px',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            display: '-webkit-box',
                                                                            "-webkitLineClamp": '1',
                                                                            "-webkitBoxOrient": 'vertical',
                                                                        }}>{order.pickup_address_details.address}</Typography>
                                                                    </Box>

                                                                    {/* <Box sx={{
                                                                    textAlign: 'end'
                                                                }}>
                                                                    <ChevronRightIcon onClick={() => {
                                                                        setTasksTabs(true);
                                                                        setOrderTabs(false);
                                                                        setSelectedOrder(order);
                                                                    }} cursor="pointer" />
                                                                </Box> */}
                                                                    {/* <Box>
                                                                    {order.tasks.length > 0 &&
                                                                        order.tasks.map((task) => {
                                                                            return (
                                                                                <Box sx={{
                                                                                    marginBottom: '15px'
                                                                                }}>
                                                                                    <Typography sx={{
                                                                                        color: "#696cff",
                                                                                        fontSize: '15px',
                                                                                    }}>{moment.utc(order.scheduled_dt).format("DD/MM/YYYY h:mm")}</Typography>
                                                                                    <Typography sx={{
                                                                                        color: "#696cff",
                                                                                        fontSize: '15px',
                                                                                    }}>{task.contact_details?.contact_name}</Typography>
                                                                                    <Typography sx={{
                                                                                        color: '#dbdbeb99',
                                                                                        fontSize: '14px',
                                                                                    }}>{task.drop_address?.address}</Typography>
                                                                                    <Typography variant='span' sx={{
                                                                                        fontSize: '12px',
                                                                                        color: '#2c9f2c'
                                                                                    }}>{task.task_status}</Typography>
                                                                                </Box>
                                                                            )
                                                                        })
                                                                    }
                                                                </Box> */}

                                                                    {order.tasks.length > 0 &&
                                                                        order.tasks.map((task) => {
                                                                            return (
                                                                                <Box
                                                                                    onClick={() => {
                                                                                        setSelectedOrder(order);
                                                                                        setSelectedTask(task);
                                                                                        setTaskDetailsTabs(true);
                                                                                        setTasksTabs(false);
                                                                                        setOrderTabs(false);
                                                                                        handleMapChange(task, order);
                                                                                    }} sx={{
                                                                                        display: "flex",
                                                                                        alignItems: 'center',
                                                                                        cursor: "pointer",
                                                                                        justifyContent: 'space-between',
                                                                                        marginBottom: '15px',
                                                                                        '& p': {
                                                                                            fontSize: '14px',
                                                                                        }
                                                                                    }}>

                                                                                    <>
                                                                                        <Box>
                                                                                            <Typography sx={{
                                                                                                overflow: 'hidden',
                                                                                                textOverflow: 'ellipsis',
                                                                                                display: '-webkit-box',
                                                                                                "-webkitLineClamp": '1',
                                                                                                "-webkitBoxOrient": 'vertical',
                                                                                                width: '164px',
                                                                                            }}>#{task.id}</Typography>
                                                                                            <Typography sx={{
                                                                                                overflow: 'hidden',
                                                                                                textOverflow: 'ellipsis',
                                                                                                display: '-webkit-box',
                                                                                                "-webkitLineClamp": '1',
                                                                                                "-webkitBoxOrient": 'vertical',
                                                                                                width: '164px',
                                                                                            }}>{task.drop_address.address}</Typography>
                                                                                            {task?.failed_reason &&
                                                                                                <Typography sx={{
                                                                                                    overflow: 'hidden',
                                                                                                    textOverflow: 'ellipsis',
                                                                                                    display: '-webkit-box',
                                                                                                    "-webkitLineClamp": '1',
                                                                                                    "-webkitBoxOrient": 'vertical',
                                                                                                    width: '164px',
                                                                                                }}>Failed Reason: {task?.failed_reason}</Typography>}
                                                                                        </Box>
                                                                                        <Box sx={{
                                                                                            '& p': {
                                                                                                textAlign: 'right',
                                                                                            }
                                                                                        }}>
                                                                                            <Typography sx={{
                                                                                                overflow: 'hidden',
                                                                                                textOverflow: 'ellipsis',
                                                                                                display: '-webkit-box',
                                                                                                "-webkitLineClamp": '1',
                                                                                                "-webkitBoxOrient": 'vertical',
                                                                                                width: '164px',
                                                                                            }}>{renderStatusCounts(task?.task_status)}</Typography>
                                                                                            {task.branger_name && <Typography sx={{
                                                                                                overflow: 'hidden',
                                                                                                textOverflow: 'ellipsis',
                                                                                                display: '-webkit-box',
                                                                                                "-webkitLineClamp": '1',
                                                                                                "-webkitBoxOrient": 'vertical',
                                                                                                width: '164px',
                                                                                            }}>{task.branger_name}</Typography>}
                                                                                        </Box>
                                                                                    </>
                                                                                </Box>
                                                                            )
                                                                        })
                                                                    }
                                                                    <Box sx={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                    }}>
                                                                        <CustomButton buttonName="Reset Order"
                                                                            color="error"
                                                                            onClick={() => {
                                                                                setOrderID(order.id);
                                                                                setAssignTask(false);
                                                                                setShowResetTask(
                                                                                    (prevState) => !prevState
                                                                                );
                                                                            }}
                                                                        />
                                                                        <CustomButton
                                                                            onClick={() => {
                                                                                setOrderID(order.id);
                                                                                setAssignTask(false);
                                                                                setIsAssignBrangerModalOpen(true);
                                                                            }} buttonName="Assign Branger" color="primary" />
                                                                    </Box>
                                                                </Box>

                                                            </Box>


                                                        </Box>
                                                    </>
                                                ))}
                                                    {tasks?.data?.length > 0 && (
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                alignContent: "center",
                                                                color: "white"
                                                            }}
                                                        >
                                                            <IconButton
                                                                disabled={
                                                                    tasks?.page_details.previous === null
                                                                        ? true
                                                                        : false
                                                                }
                                                                sx={{
                                                                    "& svg": {
                                                                        fill: tasks?.page_details.previous === null ? "gray" : "white"
                                                                    }

                                                                }}
                                                                onClick={() => {
                                                                    setUnassignedPage(
                                                                        (prevState) => prevState - 1
                                                                    );
                                                                }}
                                                            >
                                                                <ChevronLeft />
                                                            </IconButton>
                                                            {unassignedPage}
                                                            <IconButton
                                                                disabled={
                                                                    tasks?.page_details.next > 0 ? false : true
                                                                }
                                                                onClick={() => {
                                                                    setUnassignedPage(
                                                                        (prevState) => prevState + 1
                                                                    );
                                                                }}
                                                                sx={{
                                                                    "& svg": {
                                                                        fill: tasks?.page_details.next > 0 ? "white" : "gray"
                                                                    }
                                                                }}
                                                            >
                                                                <ChevronRight />
                                                            </IconButton>
                                                        </Box>
                                                    )}</> : <Box>
                                                    <Typography sx={{
                                                        textAlign: "center",
                                                        padding: "53px",
                                                        color: "white",
                                                    }}>
                                                        No result found
                                                    </Typography>
                                                </Box>
                                            : <LottieLoader />}

                                        {/* tab 2 */}
                                        {!areAssignedTasksLoading && isAssignedTaskSuccess
                                            ? assignedTasks.data.length
                                                ? <> {
                                                    assignedTasks.data.map((order) => (
                                                        <>
                                                            <>
                                                                <Box sx={{
                                                                    color: '#dbdbeb',
                                                                    padding: '15px',
                                                                    paddingTop: '0px',
                                                                }}>
                                                                    <Box
                                                                        onClick={() => {
                                                                            setTasksTabs(true);
                                                                            setOrderTabs(false);
                                                                            setSelectedOrder(order);
                                                                            handleMapChange(order);
                                                                        }} sx={{
                                                                            cursor: "pointer",
                                                                            display: "flex",
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between',
                                                                            marginBottom: '15px',
                                                                            marginTop: '15px',
                                                                        }}>
                                                                        <Box sx={{
                                                                            display: "flex",
                                                                            alignItems: 'center',
                                                                            gap: '10px',
                                                                        }}>
                                                                            <Box sx={{
                                                                                '& img': {
                                                                                    width: '35px',
                                                                                    height: '35px',
                                                                                    borderRadius: '100%'
                                                                                }
                                                                            }}>
                                                                                <img src={profileimg} />
                                                                            </Box>
                                                                            <Box>
                                                                                <Typography>Order ID: #{order.id}</Typography>
                                                                                <Typography>{order?.merchant_first_name}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box sx={{ textAlign: "end" }}>
                                                                            <Typography>
                                                                                {moment.utc(order.scheduled_dt).format("DD/MM/YYYY h:mm")}
                                                                            </Typography>
                                                                            {/* <Typography>{order.order_status}</Typography> */}
                                                                            <CustomChips label={orderStatusMappings[order.order_status]?.label || "N/A"}
                                                                                color={orderStatusMappings[order.order_status]?.color || "info"}
                                                                                labelColor={orderStatusMappings[order.order_status]?.color || "info"} />
                                                                        </Box>
                                                                    </Box>
                                                                    <Box sx={{
                                                                        borderBottom: '1px solid #dbdbeb99',
                                                                        display: 'flex',
                                                                    }}>

                                                                        <Box sx={{
                                                                            display: 'inline-flex',
                                                                            flexDirection: 'column'
                                                                        }}>
                                                                            <Box sx={{
                                                                                width: '20px',
                                                                                height: '20px',
                                                                                lineHeight: '20px',
                                                                                backgroundColor: '#1394ff',
                                                                                borderRadius: '100%',
                                                                                textAlign: 'center',
                                                                                fontSize: '12px'

                                                                            }}>p</Box>
                                                                            {order.tasks.length > 0 &&
                                                                                order.tasks.map(() => {
                                                                                    return (
                                                                                        <><Box sx={{
                                                                                            borderLeft: "1px solid #1394ff",
                                                                                            height: '37px',
                                                                                            marginLeft: '9px'
                                                                                        }}></Box>
                                                                                            <Box sx={{
                                                                                                width: '20px',
                                                                                                height: '20px',
                                                                                                lineHeight: '20px',
                                                                                                border: "1px solid #1394ff",
                                                                                                borderRadius: '100%',
                                                                                                textAlign: 'center',
                                                                                                fontSize: '12px'

                                                                                            }}>D</Box></>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Box>
                                                                        <Box sx={{
                                                                            maxWidth: '-webkit-fill-available',
                                                                            width: '100%',
                                                                            padding: '0px 0px 10px 15px',
                                                                        }}>

                                                                            <Box sx={{
                                                                                marginBottom: '15px',
                                                                            }}>
                                                                                <Typography sx={{
                                                                                    color: "#dbdbeb99",
                                                                                    fontSize: '14px',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    display: '-webkit-box',
                                                                                    "-webkitLineClamp": '1',
                                                                                    "-webkitBoxOrient": 'vertical',
                                                                                }}>{order.pickup_address_details.address}</Typography>
                                                                            </Box>

                                                                            {/* <Box sx={{
                                                                    textAlign: 'end'
                                                                }}>
                                                                    <ChevronRightIcon onClick={() => {
                                                                        setTasksTabs(true);
                                                                        setOrderTabs(false);
                                                                        setSelectedOrder(order);
                                                                    }} cursor="pointer" />
                                                                </Box> */}
                                                                            {/* <Box>
                                                                    {order.tasks.length > 0 &&
                                                                        order.tasks.map((task) => {
                                                                            return (
                                                                                <Box sx={{
                                                                                    marginBottom: '15px'
                                                                                }}>
                                                                                    <Typography sx={{
                                                                                        color: "#696cff",
                                                                                        fontSize: '15px',
                                                                                    }}>{moment.utc(order.scheduled_dt).format("DD/MM/YYYY h:mm")}</Typography>
                                                                                    <Typography sx={{
                                                                                        color: "#696cff",
                                                                                        fontSize: '15px',
                                                                                    }}>{task.contact_details?.contact_name}</Typography>
                                                                                    <Typography sx={{
                                                                                        color: '#dbdbeb99',
                                                                                        fontSize: '14px',
                                                                                    }}>{task.drop_address?.address}</Typography>
                                                                                    <Typography variant='span' sx={{
                                                                                        fontSize: '12px',
                                                                                        color: '#2c9f2c'
                                                                                    }}>{task.task_status}</Typography>
                                                                                </Box>
                                                                            )
                                                                        })
                                                                    }
                                                                </Box> */}

                                                                            {order.tasks.length > 0 &&
                                                                                order.tasks.map((task) => {
                                                                                    return (
                                                                                        <Box
                                                                                            onClick={() => {
                                                                                                setSelectedOrder(order);
                                                                                                setSelectedTask(task);
                                                                                                setTaskDetailsTabs(true);
                                                                                                setTasksTabs(false);
                                                                                                setOrderTabs(false);
                                                                                                handleMapChange(task, order);
                                                                                            }} sx={{
                                                                                                display: "flex",
                                                                                                alignItems: 'center',
                                                                                                cursor: "pointer",
                                                                                                justifyContent: 'space-between',
                                                                                                marginBottom: '15px',
                                                                                                '& p': {
                                                                                                    fontSize: '14px',
                                                                                                }
                                                                                            }}>

                                                                                            <>
                                                                                                <Box>
                                                                                                    <Typography sx={{
                                                                                                        overflow: 'hidden',
                                                                                                        textOverflow: 'ellipsis',
                                                                                                        display: '-webkit-box',
                                                                                                        "-webkitLineClamp": '1',
                                                                                                        "-webkitBoxOrient": 'vertical',
                                                                                                        width: '164px',
                                                                                                    }}>#{task.id}</Typography>
                                                                                                    <Typography sx={{
                                                                                                        overflow: 'hidden',
                                                                                                        textOverflow: 'ellipsis',
                                                                                                        display: '-webkit-box',
                                                                                                        "-webkitLineClamp": '1',
                                                                                                        "-webkitBoxOrient": 'vertical',
                                                                                                        width: '164px',
                                                                                                    }}>{task.drop_address.address}</Typography>
                                                                                                </Box>
                                                                                                <Box sx={{
                                                                                                    '& p': {
                                                                                                        textAlign: 'right',
                                                                                                    }
                                                                                                }}>
                                                                                                    <Typography sx={{
                                                                                                        overflow: 'hidden',
                                                                                                        textOverflow: 'ellipsis',
                                                                                                        display: '-webkit-box',
                                                                                                        "-webkitLineClamp": '1',
                                                                                                        "-webkitBoxOrient": 'vertical',
                                                                                                        width: '164px',
                                                                                                    }}>{renderStatusCounts(task?.task_status)}</Typography>
                                                                                                    {task.branger_name && <Typography sx={{
                                                                                                        overflow: 'hidden',
                                                                                                        textOverflow: 'ellipsis',
                                                                                                        display: '-webkit-box',
                                                                                                        "-webkitLineClamp": '1',
                                                                                                        "-webkitBoxOrient": 'vertical',
                                                                                                        width: '164px',
                                                                                                    }}>{task.branger_name}</Typography>}
                                                                                                </Box>
                                                                                            </>
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <Box sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between"
                                                                            }}>
                                                                                <CustomButton buttonName={<Box sx={{ display: "flex", gap: "5px", minWidth: "130px", "& svg": { fill: "#fff !important" } }}><FmdGoodIcon /> Track order</Box>} href={`/tracking/${order?.tasks?.[0]?.tracking_hash}`} target="_blank" color="info" />
                                                                                <CustomButton buttonName="Reset Order"
                                                                                    color="error"
                                                                                    onClick={() => {
                                                                                        setOrderID(order.id);
                                                                                        setAssignTask(false);
                                                                                        setShowResetTask(
                                                                                            (prevState) => !prevState
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </>
                                                        </>
                                                    ))}
                                                    {assignedTasks?.data?.length > 0 && (
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                alignContent: "center",
                                                                color: "white"
                                                            }}
                                                        >
                                                            <IconButton
                                                                disabled={
                                                                    assignedTasks?.page_details.previous === null
                                                                        ? true
                                                                        : false
                                                                }
                                                                sx={{
                                                                    "& svg": {
                                                                        fill: assignedTasks?.page_details.previous === null ? "gray" : "white"
                                                                    }

                                                                }}
                                                                onClick={() => {
                                                                    setAssignedPage(
                                                                        (prevState) => prevState - 1
                                                                    );
                                                                }}
                                                            >
                                                                <ChevronLeft />
                                                            </IconButton>
                                                            {unassignedPage}
                                                            <IconButton
                                                                disabled={
                                                                    assignedTasks?.page_details.next > 0 ? false : true
                                                                }
                                                                onClick={() => {
                                                                    setAssignedPage(
                                                                        (prevState) => prevState + 1
                                                                    );
                                                                }}
                                                                sx={{
                                                                    "& svg": {
                                                                        fill: assignedTasks?.page_details.next > 0 ? "white" : "gray"
                                                                    }
                                                                }}
                                                            >
                                                                <ChevronRight />
                                                            </IconButton>
                                                        </Box>
                                                    )}</> : <Box>
                                                    <Typography sx={{
                                                        textAlign: "center",
                                                        padding: "53px",
                                                        color: "white",
                                                    }}>
                                                        No result found
                                                    </Typography>
                                                </Box>
                                            : <LottieLoader />}

                                        {/* tab 3 */}
                                        {!areCompletedTasksLoading && isCompletedTaskSuccess
                                            ? completedTasks.data.length
                                                ? <> {completedTasks.data.map((order) => (
                                                    <>
                                                        <Box sx={{
                                                            color: '#dbdbeb',
                                                            padding: '15px',
                                                            paddingTop: '0px',
                                                        }}>
                                                            <Box
                                                                onClick={() => {
                                                                    setTasksTabs(true);
                                                                    setOrderTabs(false);
                                                                    setSelectedOrder(order);
                                                                    handleMapChange(order);
                                                                }} sx={{
                                                                    cursor: "pointer",
                                                                    display: "flex",
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    marginBottom: '15px',
                                                                    marginTop: '15px',
                                                                }}>
                                                                <Box sx={{
                                                                    display: "flex",
                                                                    alignItems: 'center',
                                                                    gap: '10px',
                                                                }}>
                                                                    <Box sx={{
                                                                        '& img': {
                                                                            width: '35px',
                                                                            height: '35px',
                                                                            borderRadius: '100%'
                                                                        }
                                                                    }}>
                                                                        <img src={profileimg} />
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography>Order ID: #{order.id}</Typography>
                                                                        <Typography>{order?.merchant_first_name}</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ textAlign: "end" }}>
                                                                    <Typography>
                                                                        {moment.utc(order.scheduled_dt).format("DD/MM/YYYY h:mm")}
                                                                    </Typography>
                                                                    {/* <Typography>{order.order_status}</Typography> */}
                                                                    <CustomChips label={orderStatusMappings[order.order_status]?.label || "N/A"}
                                                                        color={orderStatusMappings[order.order_status]?.color || "info"}
                                                                        labelColor={orderStatusMappings[order.order_status]?.color || "info"} />
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{
                                                                borderBottom: '1px solid #dbdbeb99',
                                                                display: 'flex',
                                                            }}>

                                                                <Box sx={{
                                                                    display: 'inline-flex',
                                                                    flexDirection: 'column'
                                                                }}>
                                                                    <Box sx={{
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        lineHeight: '20px',
                                                                        backgroundColor: '#1394ff',
                                                                        borderRadius: '100%',
                                                                        textAlign: 'center',
                                                                        fontSize: '12px'

                                                                    }}>p</Box>
                                                                    {order.tasks.length > 0 &&
                                                                        order.tasks.map(() => {
                                                                            return (
                                                                                <><Box sx={{
                                                                                    borderLeft: "1px solid #1394ff",
                                                                                    height: '37px',
                                                                                    marginLeft: '9px'
                                                                                }}></Box>
                                                                                    <Box sx={{
                                                                                        width: '20px',
                                                                                        height: '20px',
                                                                                        lineHeight: '20px',
                                                                                        border: "1px solid #1394ff",
                                                                                        borderRadius: '100%',
                                                                                        textAlign: 'center',
                                                                                        fontSize: '12px'

                                                                                    }}>D</Box></>
                                                                            )
                                                                        })
                                                                    }
                                                                </Box>
                                                                <Box sx={{
                                                                    maxWidth: '-webkit-fill-available',
                                                                    width: '100%',
                                                                    padding: '0px 0px 10px 15px',
                                                                }}>

                                                                    <Box sx={{
                                                                        marginBottom: '15px',
                                                                    }}>
                                                                        <Typography sx={{
                                                                            color: "#dbdbeb99",
                                                                            fontSize: '14px',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            display: '-webkit-box',
                                                                            "-webkitLineClamp": '1',
                                                                            "-webkitBoxOrient": 'vertical',
                                                                        }}>{order.pickup_address_details.address}</Typography>
                                                                    </Box>

                                                                    {/* <Box sx={{
                                                                    textAlign: 'end'
                                                                }}>
                                                                    <ChevronRightIcon onClick={() => {
                                                                        setTasksTabs(true);
                                                                        setOrderTabs(false);
                                                                        setSelectedOrder(order);
                                                                    }} cursor="pointer" />
                                                                </Box> */}
                                                                    {/* <Box>
                                                                    {order.tasks.length > 0 &&
                                                                        order.tasks.map((task) => {
                                                                            return (
                                                                                <Box sx={{
                                                                                    marginBottom: '15px'
                                                                                }}>
                                                                                    <Typography sx={{
                                                                                        color: "#696cff",
                                                                                        fontSize: '15px',
                                                                                    }}>{moment.utc(order.scheduled_dt).format("DD/MM/YYYY h:mm")}</Typography>
                                                                                    <Typography sx={{
                                                                                        color: "#696cff",
                                                                                        fontSize: '15px',
                                                                                    }}>{task.contact_details?.contact_name}</Typography>
                                                                                    <Typography sx={{
                                                                                        color: '#dbdbeb99',
                                                                                        fontSize: '14px',
                                                                                    }}>{task.drop_address?.address}</Typography>
                                                                                    <Typography variant='span' sx={{
                                                                                        fontSize: '12px',
                                                                                        color: '#2c9f2c'
                                                                                    }}>{task.task_status}</Typography>
                                                                                </Box>
                                                                            )
                                                                        })
                                                                    }
                                                                </Box> */}

                                                                    {order.tasks.length > 0 &&
                                                                        order.tasks.map((task) => {
                                                                            return (
                                                                                <Box
                                                                                    onClick={() => {
                                                                                        setSelectedOrder(order);
                                                                                        setSelectedTask(task);
                                                                                        setTaskDetailsTabs(true);
                                                                                        setTasksTabs(false);
                                                                                        setOrderTabs(false);
                                                                                        handleMapChange(task, order)
                                                                                    }} sx={{
                                                                                        display: "flex",
                                                                                        cursor: "pointer",
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'space-between',
                                                                                        marginBottom: '15px',
                                                                                        '& p': {
                                                                                            fontSize: '14px',
                                                                                        }
                                                                                    }}>

                                                                                    <>
                                                                                        <Box>
                                                                                            <Typography sx={{
                                                                                                overflow: 'hidden',
                                                                                                textOverflow: 'ellipsis',
                                                                                                display: '-webkit-box',
                                                                                                "-webkitLineClamp": '1',
                                                                                                "-webkitBoxOrient": 'vertical',
                                                                                                width: '164px',
                                                                                            }}>#{task.id}</Typography>
                                                                                            <Typography sx={{
                                                                                                overflow: 'hidden',
                                                                                                textOverflow: 'ellipsis',
                                                                                                display: '-webkit-box',
                                                                                                "-webkitLineClamp": '1',
                                                                                                "-webkitBoxOrient": 'vertical',
                                                                                                width: '164px',
                                                                                            }}>{task.drop_address.address}</Typography>
                                                                                        </Box>
                                                                                        <Box sx={{
                                                                                            '& p': {
                                                                                                textAlign: 'right',
                                                                                            }
                                                                                        }}>
                                                                                            <Typography sx={{
                                                                                                overflow: 'hidden',
                                                                                                textOverflow: 'ellipsis',
                                                                                                display: '-webkit-box',
                                                                                                "-webkitLineClamp": '1',
                                                                                                "-webkitBoxOrient": 'vertical',
                                                                                                width: '164px',
                                                                                            }}>{renderStatusCounts(task?.task_status)}</Typography>
                                                                                            {task.branger_name && <Typography sx={{
                                                                                                overflow: 'hidden',
                                                                                                textOverflow: 'ellipsis',
                                                                                                display: '-webkit-box',
                                                                                                "-webkitLineClamp": '1',
                                                                                                "-webkitBoxOrient": 'vertical',
                                                                                                width: '164px',
                                                                                            }}>{task.branger_name}</Typography>}
                                                                                        </Box>
                                                                                    </>
                                                                                </Box>
                                                                            )
                                                                        })
                                                                    }
                                                                    <Box sx={{
                                                                        textAlign: 'end',
                                                                    }}>
                                                                        <CustomButton buttonName="Reset Order"
                                                                            color="error"
                                                                            onClick={() => {
                                                                                setOrderID(order.id);
                                                                                setAssignTask(false);
                                                                                setShowResetTask(
                                                                                    (prevState) => !prevState
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box >
                                                    </>
                                                ))} <>
                                                        {completedTasks?.data?.length > 0 && (
                                                            <Box
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    alignContent: "center",
                                                                    color: "white"

                                                                }}
                                                            >
                                                                <IconButton
                                                                    disabled={
                                                                        completedTasks?.page_details.previous === null
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onClick={() => {
                                                                        setCompletedPage(
                                                                            (prevState) => prevState - 1
                                                                        );
                                                                    }}
                                                                    sx={{
                                                                        "& svg": {
                                                                            fill: completedTasks?.page_details.next > 0 ? "white" : "gray"
                                                                        }
                                                                    }}
                                                                >
                                                                    <ChevronLeft />
                                                                </IconButton>
                                                                {completedPage}
                                                                <IconButton
                                                                    disabled={
                                                                        completedTasks?.page_details.next > 0
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    onClick={() => {
                                                                        setCompletedPage(
                                                                            (prevState) => prevState + 1
                                                                        );
                                                                    }}
                                                                    sx={{
                                                                        "& svg": {
                                                                            fill: completedTasks?.page_details.next > 0 ? "white" : "gray"
                                                                        }
                                                                    }}
                                                                >
                                                                    <ChevronRight />
                                                                </IconButton>
                                                            </Box>
                                                        )}</></> : <Box>
                                                    <Typography sx={{
                                                        textAlign: "center",
                                                        padding: "53px",
                                                        color: "white",
                                                    }}>
                                                        No result found
                                                    </Typography>
                                                </Box> : <LottieLoader />}
                                    </CustomTabs>
                                </Box>}

                            {/* tasks Tab */}
                            {tasksTabs &&
                                <Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "15px", color: "#fff", backgroundColor: '#696cff' }}>
                                        <Typography>ORDERS #{selectedOrder?.id}</Typography>
                                        <CloseIcon onClick={(e) => {
                                            setTasksTabs(false);
                                            setOrderTabs(true)
                                        }}
                                            cursor="pointer" />
                                    </Box>
                                    <CustomTabs
                                        isBacis={true}
                                        activeTab={activeInnerOrderTab}
                                        onChange={(e, value) => setActiveInnerOrderTab(value)}
                                        tabsData={innerOrderTab}>

                                        <Box sx={{
                                            color: '#dbdbeb',
                                            padding: '15px',
                                        }}>
                                            <List>
                                                <Box sx={{
                                                    display: 'flex',
                                                    borderBottom: '1px solid #dbdbeb99',
                                                    marginBottom: "5px",
                                                    '& li': {
                                                        fontSize: '14px',
                                                        padding: '0px 14px',
                                                        '&:first-child': {
                                                            color: "#dbdbeb99",
                                                            paddingLeft: "5px"
                                                        }
                                                    }
                                                }}>
                                                    <ListItem><strong>Order ID:</strong></ListItem>
                                                    <ListItem>{selectedOrder?.id}</ListItem>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    borderBottom: '1px solid #dbdbeb99',
                                                    marginBottom: "5px",
                                                    '& li': {
                                                        fontSize: '14px',
                                                        padding: '0px 14px',
                                                        '&:first-child': {
                                                            color: "#dbdbeb99",
                                                            paddingLeft: "5px"
                                                        }
                                                    }
                                                }}>
                                                    <ListItem><strong>Scheduled Date:</strong></ListItem>
                                                    <ListItem>{moment
                                                        .utc(selectedOrder?.scheduled_dt)
                                                        .format("DD/MM/YYYY h:mm")}</ListItem>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    borderBottom: '1px solid #dbdbeb99',
                                                    marginBottom: "5px",
                                                    '& li': {
                                                        fontSize: '14px',
                                                        padding: '0px 14px',
                                                        '&:first-child': {
                                                            color: "#dbdbeb99",
                                                            paddingLeft: "5px"
                                                        }

                                                    }
                                                }}>
                                                    <ListItem><strong>Pickup Address:</strong></ListItem>
                                                    <ListItem>{selectedOrder?.pickup_address_details?.address}</ListItem>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    borderBottom: '1px solid #dbdbeb99',
                                                    marginBottom: "5px",
                                                    '& li': {
                                                        fontSize: '14px',
                                                        padding: '0px 14px',
                                                        '&:first-child': {
                                                            color: "#dbdbeb99",
                                                            paddingLeft: "5px"
                                                        }
                                                    }
                                                }}>
                                                    <ListItem><strong>Business Name:</strong></ListItem>
                                                    <ListItem>{selectedOrder.merchant_business_name}</ListItem>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    borderBottom: '1px solid #dbdbeb99',
                                                    marginBottom: "5px",
                                                    '& li': {
                                                        fontSize: '14px',
                                                        padding: '0px 14px',
                                                        '&:first-child': {
                                                            color: "#dbdbeb99",
                                                            paddingLeft: "5px"
                                                        }
                                                    }
                                                }}>
                                                    <ListItem><strong>Merchant Name:</strong></ListItem>
                                                    <ListItem>{selectedOrder.merchant_first_name +
                                                        " " +
                                                        selectedOrder.merchant_last_name}</ListItem>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    borderBottom: '1px solid #dbdbeb99',
                                                    marginBottom: "5px",
                                                    '& li': {
                                                        fontSize: '14px',
                                                        padding: '0px 14px',
                                                        '&:first-child': {
                                                            color: "#dbdbeb99",
                                                            paddingLeft: "5px"
                                                        }
                                                    }
                                                }}>
                                                    <ListItem><strong>Merchant Contact:</strong></ListItem>
                                                    <ListItem>{selectedOrder.merchant_phone}</ListItem>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    borderBottom: '1px solid #dbdbeb99',
                                                    marginBottom: "5px",
                                                    '& li': {
                                                        fontSize: '14px',
                                                        padding: '0px 14px',
                                                        '&:first-child': {
                                                            color: "#dbdbeb99",
                                                            paddingLeft: "5px"
                                                        }
                                                    }
                                                }}>
                                                    <ListItem><strong>Order Status:</strong></ListItem>
                                                    <ListItem>
                                                        <CustomChips label={orderStatusMappings[selectedOrder?.order_status]?.label || "N/A"}
                                                            color={orderStatusMappings[selectedOrder?.order_status]?.color || "info"}
                                                            labelColor={orderStatusMappings[selectedOrder?.order_status]?.color || "info"} />
                                                    </ListItem>
                                                </Box>
                                            </List>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}>
                                                {activeOrderTab !== "0" || activeOrderTab !== 0 ?
                                                    <CustomButton buttonName="Reset Order"
                                                        color="error"
                                                        onClick={() => {
                                                            setOrderID(selectedOrder.id);
                                                            setAssignTask(false);
                                                            setShowResetTask(
                                                                (prevState) => !prevState
                                                            );
                                                        }}
                                                    /> :
                                                    <><CustomButton buttonName="Assign Branger" onClick={() => {
                                                        setOrderID(selectedOrder.id);
                                                        setAssignTask(false);
                                                        setIsAssignBrangerModalOpen(true);
                                                    }} color="primary" />
                                                        <CustomButton buttonName="Optimize Path" onClick={() =>
                                                            callGetOptimizePath(selectedOrder.id)
                                                        } color="primary" /></>}
                                            </Box>
                                            <Box sx={{
                                                margin: '20px 0'
                                            }}>
                                                <Typography variant='h6'>TASKS:</Typography>
                                                {selectedOrder.tasks.map((task) => {
                                                    return (
                                                        <>
                                                            <Box sx={{
                                                                backgroundColor: '#2b2c40',
                                                                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 10px 0px',
                                                                padding: '10px',
                                                                marginTop: '15px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                border: '1px solid #dbdbeb99',
                                                                borderRadius: '5px',
                                                            }}>

                                                                <Box>
                                                                    <Typography>Task#: {task.id}</Typography>
                                                                    <Box sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        gap: "8px",

                                                                    }}>
                                                                        <Typography>Task Status: </Typography>
                                                                        <CustomChips label={task.task_status} color="primary" labelColor="primary" />
                                                                    </Box>
                                                                    {task?.failed_reason && <Box sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        gap: "8px",

                                                                    }}>
                                                                        <Typography>Failed Reason: </Typography>
                                                                        <Typography sx={{ color: "#ED6C02" }}>{task.failed_reason}</Typography>
                                                                    </Box>}
                                                                </Box>
                                                                <Box>
                                                                    <ChevronRightIcon onClick={() => {
                                                                        setSelectedTask(task)
                                                                        setTaskDetailsTabs(true);
                                                                        setTasksTabs(false);
                                                                        setOrderTabs(false);
                                                                    }} cursor="pointer" />
                                                                </Box>
                                                            </Box>
                                                        </>
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            color: '#dbdbeb',
                                            padding: '15px',
                                        }}>
                                            <Box sx={{
                                                textAlign: "center",
                                                marginBottom: "20px",
                                                display: "flex",
                                                justifyContent: "center"
                                            }}>
                                                <CustomButton color="primary" outline={true}
                                                    onClick={() => setIsAttachmentOpen(!isAttachmentOpen)}
                                                    buttonName="Add attachments" />
                                            </Box>
                                            <Box>
                                                {attachments?.length ? (
                                                    attachments.map((atta) => {
                                                        return (
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    padding: "10px",
                                                                    alignItems: "center",
                                                                    marginBottom: "10px",
                                                                    boxShadow:
                                                                        "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        width: "100px",
                                                                        height: "100px",
                                                                        "& img": {
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            objectFit: "cover",
                                                                            borderRadius: "50%"
                                                                        },
                                                                    }}
                                                                >
                                                                    <Link
                                                                        to={atta.attachment} target="_blank">
                                                                        <img src={atta.attachment} />
                                                                    </Link>
                                                                </Box>
                                                                <Box>
                                                                    <Typography>
                                                                        comment: {atta.comment}
                                                                    </Typography>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        marginLeft: "auto",
                                                                    }}
                                                                >
                                                                    <DeleteIcon
                                                                        cursor="pointer"
                                                                        onClick={() =>
                                                                            handleDeleteAttachments(atta.id)
                                                                        }
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        );
                                                    })
                                                ) : (
                                                    <Box sx={{ textAlign: "center" }}>Attachments Not Found</Box>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            color: '#dbdbeb',
                                            padding: '15px',
                                        }}>
                                            <Typography>

                                                Hello world 3
                                            </Typography>
                                        </Box>
                                    </CustomTabs>
                                </Box>}

                            {/* Task details tab*/}
                            {taskDetailsTabs && <Box >
                                <Box sx={{ display: "flex", backgroundColor: '#696cff', justifyContent: "space-between", padding: "15px", color: "#fff" }}>
                                    <Typography>Task# {selectedTask.id}</Typography>
                                    <CloseIcon onClick={(e) => {
                                        setTaskDetailsTabs(false);
                                        setTasksTabs(true);
                                    }} cursor="pointer" />
                                </Box>
                                <CustomTabs
                                    isBacis={true}
                                    activeTab={activeTasksTab}
                                    onChange={(e, value) => setActiveTasksTab(value)}
                                    tabsData={tasksTab}>
                                    {/* tab 1 */}
                                    <Box sx={{
                                        color: '#dbdbeb',
                                        padding: '15px',
                                    }}>
                                        <List>
                                            <Box sx={{
                                                display: 'flex',
                                                borderBottom: '1px solid #dbdbeb99',
                                                marginBottom: "5px",
                                                '& li': {
                                                    fontSize: '14px',
                                                    padding: '0px 14px',
                                                    '&:first-child': {
                                                        color: "#dbdbeb99",
                                                        paddingLeft: "5px"
                                                    }
                                                }
                                            }}>
                                                <ListItem>Task Id:</ListItem>
                                                <ListItem>{selectedTask.id}</ListItem>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                borderBottom: '1px solid #dbdbeb99',
                                                marginBottom: "5px",
                                                '& li': {
                                                    fontSize: '14px',
                                                    padding: '0px 14px',
                                                    '&:first-child': {
                                                        color: "#dbdbeb99",
                                                        paddingLeft: "5px"
                                                    }
                                                }
                                            }}>
                                                <ListItem>Order Id:</ListItem>
                                                <ListItem>{selectedOrder.id}</ListItem>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                borderBottom: '1px solid #dbdbeb99',
                                                marginBottom: "5px",
                                                '& li': {
                                                    fontSize: '14px',
                                                    padding: '0px 14px',
                                                    '&:first-child': {
                                                        color: "#dbdbeb99",
                                                        paddingLeft: "5px"
                                                    }

                                                }
                                            }}>
                                                <ListItem>Customer Name:</ListItem>
                                                <ListItem>{selectedTask.contact_details.contact_name}</ListItem>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                borderBottom: '1px solid #dbdbeb99',
                                                marginBottom: "5px",
                                                '& li': {
                                                    fontSize: '14px',
                                                    padding: '0px 14px',
                                                    '&:first-child': {
                                                        color: "#dbdbeb99",
                                                        paddingLeft: "5px"
                                                    }
                                                }
                                            }}>
                                                <ListItem>Customer Contact Number:</ListItem>
                                                <ListItem>{selectedTask.contact_details.contact_phone}</ListItem>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                borderBottom: '1px solid #dbdbeb99',
                                                marginBottom: "5px",
                                                '& li': {
                                                    fontSize: '14px',
                                                    padding: '0px 14px',
                                                    '&:first-child': {
                                                        color: "#dbdbeb99",
                                                        paddingLeft: "5px"
                                                    }
                                                }
                                            }}>
                                                <ListItem>Customer Email:</ListItem>
                                                <ListItem>{selectedTask.contact_details.contact_email}</ListItem>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                borderBottom: '1px solid #dbdbeb99',
                                                marginBottom: "5px",
                                                '& li': {
                                                    fontSize: '14px',
                                                    padding: '0px 14px',
                                                    '&:first-child': {
                                                        color: "#dbdbeb99",
                                                        paddingLeft: "5px"
                                                    }
                                                }
                                            }}>
                                                <ListItem>Pickup Address:</ListItem>
                                                <ListItem>{selectedOrder.pickup_address_details.address}</ListItem>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                borderBottom: '1px solid #dbdbeb99',
                                                marginBottom: "5px",
                                                '& li': {
                                                    fontSize: '14px',
                                                    padding: '0px 14px',
                                                    '&:first-child': {
                                                        color: "#dbdbeb99",
                                                        paddingLeft: "5px"
                                                    }
                                                }
                                            }}>
                                                <ListItem>Drop Address:</ListItem>
                                                <ListItem>{selectedTask.drop_address.address}</ListItem>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                borderBottom: '1px solid #dbdbeb99',
                                                marginBottom: "5px",
                                                '& li': {
                                                    fontSize: '14px',
                                                    padding: '0px 14px',
                                                    '&:first-child': {
                                                        color: "#dbdbeb99",
                                                        paddingLeft: "5px"
                                                    }
                                                }
                                            }}>
                                                <ListItem>Task Status:</ListItem>
                                                <ListItem>{renderStatusCounts(selectedTask.task_status)}</ListItem>
                                            </Box>
                                            {selectedTask?.failed_reason && <Box sx={{
                                                display: 'flex',
                                                borderBottom: '1px solid #dbdbeb99',
                                                marginBottom: "5px",
                                                '& li': {
                                                    fontSize: '14px',
                                                    padding: '0px 14px',
                                                    '&:first-child': {
                                                        color: "#dbdbeb99",
                                                        paddingLeft: "5px"
                                                    }
                                                }
                                            }}>
                                                <ListItem>Failed reason:</ListItem>
                                                <ListItem><Typography sx={{ color: "#ED6C02" }}>{selectedTask.failed_reason}</Typography></ListItem>
                                            </Box>}
                                            <Box sx={{
                                                display: 'flex',
                                                borderBottom: '1px solid #dbdbeb99',
                                                marginBottom: "5px",
                                                '& li': {
                                                    fontSize: '14px',
                                                    padding: '0px 14px',
                                                    '&:first-child': {
                                                        color: "#dbdbeb99",
                                                        paddingLeft: "5px"
                                                    }
                                                }
                                            }}>
                                                <ListItem>Order Status:</ListItem>
                                                <ListItem>
                                                    {/* {selectedOrder.order_status} */}
                                                    <CustomChips label={orderStatusMappings[selectedOrder?.order_status]?.label || "N/A"}
                                                        color={orderStatusMappings[selectedOrder?.order_status]?.color || "info"}
                                                        labelColor={orderStatusMappings[selectedOrder?.order_status]?.color || "info"} />
                                                </ListItem>
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                borderBottom: '1px solid #dbdbeb99',
                                                marginBottom: "5px",
                                                '& li': {
                                                    fontSize: '14px',
                                                    padding: '0px 14px',
                                                    '&:first-child': {
                                                        color: "#dbdbeb99",
                                                        paddingLeft: "5px"
                                                    },
                                                    '& a': {
                                                        color: "#03c3ec"
                                                    }
                                                }
                                            }}>
                                                <ListItem>Track Task:</ListItem>
                                                <ListItem>
                                                    <Link
                                                        to={`/tracking/${selectedTask.tracking_hash}`}  // Pass both hash and id in URL
                                                        target="_blank"
                                                    >
                                                        Click here to track
                                                    </Link>
                                                </ListItem>
                                            </Box>
                                        </List>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            {activeOrderTab === "0" || activeOrderTab === 0 ?
                                                <CustomButton buttonName="Assign Branger" onClick={() => {
                                                    setTaskID(selectedTask.id);
                                                    setAssignTask(true);
                                                    setIsAssignBrangerModalOpen(true);
                                                }} color="primary" />
                                                : <CustomButton buttonName="Reset Task"
                                                    color="error"
                                                    onClick={() => {
                                                        setTaskID(selectedTask.id);
                                                        setAssignTask(true);
                                                        setShowResetTask(
                                                            (prevState) => !prevState
                                                        );
                                                    }}
                                                />}
                                        </Box>

                                    </Box>
                                    <Box sx={{
                                        color: '#dbdbeb',
                                        padding: '15px',
                                    }}>
                                        <Typography>
                                            Hello world 2
                                        </Typography>

                                    </Box>
                                    <Box sx={{
                                        color: '#dbdbeb',
                                        padding: '15px',
                                    }}>
                                        <Typography>

                                            Hello world 3
                                        </Typography>
                                    </Box>
                                </CustomTabs>
                            </Box>
                            }
                        </Box>

                        <Box onClick={handleOrderSlide} sx={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: '#2b2c40f2',
                            zIndex: '999',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: 'all 0.3s',
                            borderBottomRightRadius: '5px',
                            position: 'absolute',
                            top: '0',
                            right: '-40px',
                            '& svg': {
                                fill: '#fff'
                            }
                        }}>
                            {isOrderSlideOpen ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
                        </Box>
                    </Box>

                    <Box sx={{
                        width: mapWidth,
                        height: '100vh',
                        transition: 'width 400ms ease',
                        margin: '0 auto',
                        position: 'relative'
                    }}>
                        <TempMap points={markers}
                            setClearMap={setClearMap}
                            clearMap={clearMap}
                            openBrangerDetails={openBrangerDetails}
                            allDeliveries={allDeliveries}
                            freeBusyBranger={freeBusyBranger}
                            setIsBrangerDetailsOpen={setIsBrangerDetailsOpen}
                            fetchBrangerById={fetchBrangerById}
                        />
                    </Box>

                    <Box className="right-tab-wrp" sx={{
                        width: isBrangerSlideOpen ? '400px' : "0px",
                        flex: isBrangerSlideOpen ? '0 0 400px' : "inherit",
                        transition: 'width 400ms ease, flex 300ms ease',
                        position: 'relative',
                        display: 'flex',
                    }}>
                        <Box
                            onClick={handleBrangerSlide} sx={{
                                width: '40px',
                                height: '40px',
                                backgroundColor: '#2b2c40f2',
                                zIndex: '99',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                right: isBrangerSlideOpen ? "24px" : '400px',
                                justifyContent: 'center',
                                transition: 'all 0.3s',
                                borderBottomLeftRadius: '5px',
                                position: 'absolute',
                                top: '0',
                                left: '-40px',
                                '& svg': {
                                    fill: '#fff'
                                }
                            }}>
                            {isBrangerSlideOpen ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
                        </Box>
                        <Box sx={{
                            backgroundColor: '#2b2c40',
                            overflowY: 'auto',
                            height: '100vh',
                            width: '100%',
                            zIndex: '99',
                            transition: 'all 0.3s',
                        }}>
                            {!isBrangerDetailsOpen && <Box sx={{ display: "flex", justifyContent: "space-between", padding: "16px 16px", color: "#dbdbeb99", }}>
                                <Box sx={{
                                    paddingLeft: '10px',
                                }}>
                                    <Typography>BRANGERS</Typography>
                                </Box>
                                <Box><FilterAltIcon onClick={(e) => handleBrangerFilterMenu(e)} cursor="pointer" /></Box>
                            </Box>}

                            {!areBrangersLoading && isBrangerReturnSuccess && !isBrangerDetailsOpen &&
                                brangersReturned.data.map((free) => {
                                    return (
                                        <Box sx={{
                                            padding: '15px',
                                            cursor: "pointer"
                                        }}
                                            onClick={() => {
                                                setIsBrangerDetailsOpen(true);
                                                setSelectedBranger(free);
                                                setBrangerTaskSortList([]);
                                                fetchBrangerTaskSortList(free.id);
                                            }}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginBottom: '10px',
                                                border: '1px solid #fff',
                                                padding: '6px',
                                                borderRadius: '4px',
                                            }}>
                                                <Box>
                                                    <Typography sx={{ color: '#dbdbeb', fontSize: '16px', fontWeight: 'bold', marginBottom: '3px', }}>{free?.first_name + " " + free?.last_name}</Typography>
                                                    <Typography sx={{ color: '#dbdbeb', fontSize: '16px', fontWeight: 'bold', marginBottom: '3px', }}>ID: {free?.id}</Typography>
                                                    <Typography sx={{ color: '#dbdbeb', fontSize: '14px', fontWeight: '400', marginBottom: '5px', }}>{free?.phone}</Typography>
                                                    <Typography sx={{ color: '#dbdbeb', fontSize: '14px', fontWeight: '400', marginBottom: '0px', }}>Task: {free?.branger_details?.ongoing_tasks_num}</Typography>
                                                </Box>
                                                <CustomChips color='success' label={free?.branger_details?.branger_status} labelColor='success' />
                                            </Box>
                                        </Box>
                                    )
                                })}

                            {!areBusyBrangersLoading &&
                                isBusyBrangerReturnSuccess && !isBrangerDetailsOpen && busyBrangersReturned?.data?.map((busy) => {
                                    return (
                                        <Box sx={{
                                            padding: '15px',
                                            cursor: "pointer"
                                        }}
                                            onClick={() => {
                                                setIsBrangerDetailsOpen(true);
                                                setSelectedBranger(busy);
                                                setBrangerTaskSortList([]);
                                                fetchBrangerTaskSortList(busy.id);
                                            }}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginBottom: '10px',
                                                border: '1px solid #fff',
                                                padding: '6px',
                                                borderRadius: '4px',
                                            }}>
                                                <Box>
                                                    <Typography sx={{ color: '#dbdbeb', fontSize: '16px', fontWeight: 'bold', marginBottom: '3px', }}>{busy?.first_name + " " + busy?.last_name}</Typography>
                                                    <Typography sx={{ color: '#dbdbeb', fontSize: '16px', fontWeight: 'bold', marginBottom: '3px', }}>ID: {busy?.id}</Typography>
                                                    <Typography sx={{ color: '#dbdbeb', fontSize: '14px', fontWeight: '400', marginBottom: '5px', }}>{busy?.phone}</Typography>
                                                    <Typography sx={{ color: '#dbdbeb', fontSize: '14px', fontWeight: '400', marginBottom: '0px', }}>Task: {busy?.branger_details?.ongoing_tasks_num}</Typography>
                                                </Box>
                                                <CustomChips color='primary' label={busy?.branger_details?.branger_status} labelColor='primary' />
                                            </Box>
                                        </Box>
                                    )
                                })}

                            {!areInactiveBrangersLoading &&
                                isInactiveBrangerReturnSuccess && !isBrangerDetailsOpen &&
                                inactiveBrangersReturned.data.map((inactive) => {
                                    return (
                                        <Box sx={{
                                            padding: '15px',
                                            cursor: "pointer"
                                        }}
                                            onClick={() => {
                                                setIsBrangerDetailsOpen(true);
                                                setSelectedBranger(inactive);
                                                setBrangerTaskSortList([]);
                                                fetchBrangerTaskSortList(inactive.id);
                                            }}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginBottom: '10px',
                                                border: '1px solid #fff',
                                                padding: '6px',
                                                borderRadius: '4px',
                                            }}>
                                                <Box>
                                                    <Typography sx={{ color: '#dbdbeb', fontSize: '16px', fontWeight: 'bold', marginBottom: '3px', }}>{inactive?.first_name + " " + inactive?.last_name}</Typography>
                                                    <Typography sx={{ color: '#dbdbeb', fontSize: '16px', fontWeight: 'bold', marginBottom: '3px', }}>ID: {inactive?.id}</Typography>
                                                    <Typography sx={{ color: '#dbdbeb', fontSize: '14px', fontWeight: '400', marginBottom: '5px', }}>{inactive?.phone}</Typography>
                                                    <Typography sx={{ color: '#dbdbeb', fontSize: '14px', fontWeight: '400', marginBottom: '0px', }}>Task: {inactive?.branger_details?.ongoing_tasks_num}</Typography>
                                                </Box>
                                                <CustomChips color='secondary' label={inactive?.branger_details?.branger_status} labelColor='secondary' />
                                            </Box>
                                        </Box>
                                    )
                                })}

                            {isBrangerDetailsOpen &&
                                <>
                                    {isBrangerLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <CircularProgress size="2rem" /></Box> :
                                        selectedBranger ?
                                            <>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    background: '#696cff',
                                                    padding: '15px',
                                                    "& svg": {
                                                        fill: "white"
                                                    }
                                                }}>
                                                    <Typography sx={{
                                                        color: '#fff',
                                                    }}>
                                                        Branger: #{selectedBranger?.id}
                                                    </Typography>
                                                    <CloseIcon cursor="pointer" onClick={() => {
                                                        setIsBrangerDetailsOpen(false);
                                                        setSelectedBranger({});
                                                        setBrangerLiveAddress('');
                                                    }} />
                                                </Box>
                                                <Box sx={{
                                                    padding: '15px',
                                                }}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',

                                                        '& p': {
                                                            color: '#dbdbeb',
                                                        }
                                                    }}>
                                                        <Typography sx={{
                                                            fontWeight: 'bold', marginRight: '5px'
                                                        }}>ID:</Typography>
                                                        <Typography sx={{
                                                            fontSize: '14px'
                                                        }}>#{selectedBranger?.id}</Typography>
                                                    </Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        '& p': {
                                                            color: '#dbdbeb',
                                                        }
                                                    }}>
                                                        <Typography sx={{
                                                            fontWeight: 'bold', marginRight: '5px'
                                                        }}>Name:</Typography>
                                                        <Typography sx={{
                                                            fontSize: '14px'
                                                        }}>{selectedBranger?.first_name + " " + selectedBranger?.last_name}</Typography>
                                                    </Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        '& p': {
                                                            color: '#dbdbeb',
                                                        }
                                                    }}>
                                                        <Typography sx={{
                                                            fontWeight: 'bold', marginRight: '5px'
                                                        }}>Phone Number:</Typography>
                                                        <Typography sx={{
                                                            fontSize: '14px'
                                                        }}>{selectedBranger?.phone}</Typography>
                                                    </Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',

                                                        '& p': {
                                                            color: '#dbdbeb',
                                                        }
                                                    }}>
                                                        <Typography sx={{
                                                            fontWeight: 'bold', marginRight: '5px'
                                                        }}>Status:</Typography>
                                                        <Typography sx={{
                                                            fontSize: '14px'
                                                        }}>{selectedBranger?.branger_details?.branger_status}</Typography>
                                                    </Box>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        '& p': {
                                                            color: '#dbdbeb',
                                                        },
                                                        '& button': {
                                                            fontSize: '12px',
                                                            padding: ' 4px 6px',
                                                        }
                                                    }}>
                                                        <Typography sx={{
                                                            fontWeight: 'bold', marginRight: '5px'
                                                        }}>Current Location:</Typography>
                                                        <CustomButton onClick={handleBrangerLastLoc} buttonName='Get Location' color='primary' />
                                                    </Box>
                                                    {brangerLiveAddress && <Box sx={{
                                                        marginTop: '5px',
                                                        '& p': {
                                                            color: '#fff',
                                                            fontSize: '14px',
                                                        }
                                                    }}>
                                                        <Typography>{brangerLiveAddress}</Typography>
                                                    </Box>}
                                                    {currentBrangerLocationData && <Box sx={{
                                                        marginTop: '5px',
                                                        '& p': {
                                                            color: '#fff',
                                                            fontSize: '14px',
                                                        }
                                                    }}>
                                                        <Typography>Last updated at: {moment
                                                            .utc(currentBrangerLocationData?.updated_at)
                                                            .format("DD/MM/YYYY h:mm")}</Typography>
                                                    </Box>}
                                                </Box>
                                                <Box sx={{
                                                    padding: '15px',
                                                }}>
                                                    <Box sx={{
                                                        padding: '0px',
                                                        marginBottom: '15px',
                                                    }}>
                                                        <Typography sx={{ color: '#dbdbeb', fontSize: '18px', fontWeight: 'bold' }}>Task:</Typography>
                                                    </Box>
                                                    {isbrangerTaskSortFecthing ? <Box><CircularProgress size="2rem" /></Box> :
                                                        brangerTaskSortList?.length > 0 ?
                                                            <DragDropContext onDragEnd={onDragEnd}>
                                                                <DraggableList brangerTaskSortList={brangerTaskSortList} setBrangerTaskSortList={setBrangerTaskSortList} />
                                                            </DragDropContext>
                                                            : <Typography variant='body1' color="#dbdbeb99">No task assigned</Typography>}
                                                </Box>
                                            </> : <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', gap: "5px" }}>
                                                <ErrorIcon color="error" /><Typography color="error">Branger details not found!</Typography></Box>
                                    }
                                </>
                            }
                        </Box>
                    </Box>
                </Box >

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={isMenuOpen}
                    onClose={handleClose}
                    sx={{
                        '& ul': {
                            padding: '15px 15px !important',
                        },
                        '& .MuiMenu-paper': {
                            minWidth: "300px",
                            backgroundColor: 'rgb(43, 44, 64)',
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 6px -3px, rgba(0, 0, 0, 0.14) 0px 9px 12px 1px, rgba(0, 0, 0, 0.12) 0px 3px 16px 2px',
                            color: "#dbdbebde",
                            overflow: 'unset',
                        },
                    }}

                    MenuListProps={{
                        'aria-labelledby': 'lock-button',
                        role: 'listbox',
                    }}
                >
                    <Box role="listbox">
                        <Box
                            role="option"
                            aria-selected={false}
                            onClick={() => {
                                // Handle click for the first item (CustomInput)
                            }}
                            mb={2}
                        >
                            <CustomInput
                                value={taskSearch}
                                onChange={(e) => {
                                    setTaskSearch(e.target.value);
                                }}
                                type="search"
                                onCancelSearch={() => {
                                    setTaskSearch("");
                                }}
                                placeholder="Order Search"
                            />
                        </Box>
                        <Box
                            role="option"
                            aria-selected={false}
                            onClick={() => {
                                // Handle click for the second item (CustomDateRangePicker)
                            }}
                            sx={{
                                '& .react-datepicker': {
                                    display: 'flex'
                                }
                            }}
                        >
                            <CustomDateRangePicker
                                dateRange={dateRange}
                                label="Filter By Date"
                                onChange={handleDateRangeChange}
                            />
                        </Box>
                    </Box>
                </Menu >

                {/* <AssignBrangerModal /> */}
                <CustomModal
                    isModalOpen={isAssignBrangerModal}
                    isShowActionButton={false}
                    title={!assignTask ? `Assign Branger For Order: ${orderID}` : `Assign Branger For Task: ${taskID}`}
                    handleClose={() => setIsAssignBrangerModalOpen(false)}
                    onCancel={() => setIsAssignBrangerModalOpen(false)}>
                    <AssignBranger
                        assignTask={assignTask}
                        orderID={orderID}
                        taskID={taskID}
                        handleRefetch={handleRefetch}
                        setIsAssignBrangerModalOpen={setIsAssignBrangerModalOpen} />
                </CustomModal>

                {/* Reset order */}
                <CustomModal isModalOpen={showResetTask}
                    isShowActionButton={false}
                    title={!assignTask ? `Reset Order: ${orderID}` : `Reset Task: ${taskID}`}
                    handleClose={() => setShowResetTask(false)}
                    onCancel={() => setShowResetTask(false)}>
                    <ResetDelivery
                        assignTask={assignTask}
                        orderID={orderID}
                        handleRefetch={handleRefetch}
                        taskID={taskID}
                        setShowResetTask={setShowResetTask}
                    />
                </CustomModal>

                {/* Attachment Modal */}
                <CustomModal isModalOpen={isAttachmentOpen} isShowActionButton={false} title="Upload Attachment" handleClose={() => setIsAttachmentOpen(false)}
                    onCancel={() => setIsAttachmentOpen(false)}>
                    <AddAttachment isAddAttachmentLoading={isAddAttachmentLoading} handleAddAttachmentSubmit={handleAddAttachmentSubmit} />
                </CustomModal>
            </Box >


            <Menu
                id="basic-menu"
                anchorEl={anchorElBranger}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isBrangerFilterOpen}
                onClose={handeBrangerFilterSearch}
                sx={{
                    '& ul': {
                        padding: '15px 15px !important',
                    },
                    '& .MuiMenu-paper': {
                        minWidth: "300px",
                        backgroundColor: 'rgb(43, 44, 64)',
                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 6px -3px, rgba(0, 0, 0, 0.14) 0px 9px 12px 1px, rgba(0, 0, 0, 0.12) 0px 3px 16px 2px',
                        color: "#dbdbebde",
                        overflow: 'unset',
                    },
                }}

                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                <Box role="listbox">
                    <Box
                        role="option"
                        aria-selected={false}
                        onClick={() => {
                            // Handle click for the first item (CustomInput)
                        }}
                        mb={2}
                    >
                        <CustomInput
                            value={brangerSearch}
                            onChange={(e) => {
                                setBrangerSearch(e.target.value);
                            }}
                            type="search"
                            onCancelSearch={() => {
                                setBrangerSearch("");
                            }}
                            placeholder="Branger Search"
                        />
                    </Box>
                </Box>
            </Menu >
        </>
    )
}

export default NewMap
