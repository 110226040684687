import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../view/dashboard/Dashboard";
import Brangers from "../view/brangers/Brangers";
import Wallet from "../view/wallet/Wallet";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Login from "../view/login/Login";
import Logout from "../view/login/Logout";
import Profile from "../view/profile/Profile";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Person2Icon from "@mui/icons-material/Person2";
import Recharge from "../view/wallet/Recharge";
import CreateBranger from "../view/brangers/CreateBranger";
import Merchants from "../view/merchants/Merchants";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CreateMerchant from "../view/merchants/CreateMerchant";
import MyOrders from "../view/my-orders/MyOrders";
import VignetteIcon from "@mui/icons-material/Vignette";
import Orders from "../view/order/Orders";
import CreateOrder from "../view/order/CreateOrder";
import CreateMyOrder from "../view/my-orders/CreateMyOrder";
import EditMyOrder from "../view/my-orders/EditMyOrder";
import PackagePayment from "../view/packages/PackagePayment";
import EditOrder from "../view/order/EditOrder";
import NewMap from "../view/map/NewMap";
import MapIcon from "@mui/icons-material/Map";
import ResetPassword from "../view/login/ResetPassword";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import AddressBook from "../view/address-book/AddressBook";
import Refund from "../view/refund/Refund";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ExternalTransactions from "../view/external-transaction/ExternalTransaction";
import PaidIcon from "@mui/icons-material/Paid";
import CreateExternalTransaction from "../view/external-transaction/CreateExternalTransaction";
import EditExternalTransaction from "../view/external-transaction/EditExternalTransaction";
import TrackingPage from "../view/tracking-page/TrackingPage";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { setCredentials } from "../store/auth/authSlice";
import PaymentSuccess from "../view/payment-success/PaymentSuccess";
import TrackPackage from "../view/map/TrackPackage";
import Fredit from "../view/fredit/Fredit";
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import TrackingLayout from "../view/tracking-page/TrackingLayout";
import Support from "../view/support/Support";
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';

export const routes = [
  {
    name: "Dashboard",
    path: "/dashboard",
    ele: <Dashboard />,
    icon: <SpaceDashboardIcon />,
    permissions: ["ADMIN", "MERCHANT"],
    isMenu: true,
  },
  {
    name: "My Orders",
    path: "/my-orders",
    ele: <MyOrders />,
    icon: <VignetteIcon />,
    permissions: ["MERCHANT"],
    isMenu: true,
  },
  {
    name: "Create My Orders",
    path: "/my-orders/create",
    ele: <CreateMyOrder />,
    permissions: ["MERCHANT"],
  },
  {
    name: "Edit My Orders",
    path: "/my-orders/:orderID",
    ele: <EditMyOrder />,
    permissions: ["MERCHANT"],
  },
  {
    name: "Wallet",
    path: "/wallet",
    ele: <Wallet />,
    icon: <AccountBalanceWalletIcon />,
    permissions: ["MERCHANT"],
    isMenu: true,
  },
  {
    name: "Orders",
    path: "/orders",
    ele: <Orders />,
    icon: <VignetteIcon />,
    permissions: ["ADMIN"],
    isMenu: true,
  },
  {
    name: "Create Order",
    path: "/orders/create",
    ele: <CreateOrder />,
    icon: <VignetteIcon />,
    permissions: ["ADMIN"],
    isMenu: false,
  },
  {
    name: "Edit Order",
    path: "/orders/:orderID",
    ele: <EditOrder />,
    permissions: ["ADMIN"],
  },
  {
    name: "Merchants",
    path: "/merchants",
    ele: <Merchants />,
    icon: <StorefrontIcon />,
    permissions: ["ADMIN"],
    isMenu: true,
  },
  {
    name: "Create Merchant",
    path: "/merchants/create",
    ele: <CreateMerchant />,
    permissions: ["ADMIN"],
    isMenu: false,
  },
  {
    name: "Edit Merchant",
    path: "/merchants/edit/:merchantID",
    ele: <CreateMerchant />,
    permissions: ["ADMIN"],
    isMenu: false,
  },
  {
    name: "Brangers",
    path: "/brangers",
    ele: <Brangers />,
    icon: <LocalShippingIcon />,
    permissions: ["ADMIN"],
    isMenu: true,
  },
  {
    name: "Create Branger",
    path: "/brangers/create",
    ele: <CreateBranger />,
    permissions: ["ADMIN"],
    isMenu: false,
  },
  {
    name: "Edit Branger",
    path: "/brangers/edit/:id",
    ele: <CreateBranger />,
    permissions: ["ADMIN"],
    isMenu: false,
  },
  {
    name: "Refund",
    path: "/refund",
    ele: <Refund />,
    permissions: ["ADMIN"],
    icon: <CurrencyExchangeIcon />,
    isMenu: true,
  },
  {
    name: "External Transactions",
    path: "/external-transactions",
    ele: <ExternalTransactions />,
    permissions: ["ADMIN"],
    icon: <PaidIcon />,
    isMenu: true,
  },
  {
    name: "Create External Transactions",
    path: "/external-transactions/create",
    ele: <CreateExternalTransaction />,
    permissions: ["ADMIN"],
  },
  {
    name: "Edit External Transactions",
    path: "/external-transactions/:id",
    ele: <EditExternalTransaction />,
    permissions: ["ADMIN"],
  },
  {
    name: "Map",
    path: "/map",
    ele: <NewMap />,
    permissions: ["ADMIN"],
    icon: <MapIcon />,
    isMenu: true,
  },
  {
    name: "Profile",
    path: "/profile",
    ele: <Profile />,
    icon: <Person2Icon />,
    permissions: ["ADMIN", "MERCHANT"],
    isMenu: true,
  },
  {
    name: "Recharge",
    path: "/recharge",
    ele: <Recharge />,
    icon: <Person2Icon />,
    permissions: ["ADMIN", "MERCHANT"],
  },
  {
    name: "Address Book",
    path: "/address_book",
    ele: <AddressBook />,
    icon: <ImportContactsIcon />,
    permissions: ["MERCHANT"],
    isMenu: true,
  },
  {
    name: "Payment Success",
    path: "/success",
    ele: <PaymentSuccess />,
    permissions: ["ADMIN", "MERCHANT"],
  },
  {
    name: "Package Payment",
    path: "/orders/packages/pay/:orderID",
    ele: <PackagePayment />,
    permissions: ["ADMIN", "MERCHANT"],
  },
  {
    name: "Fredit",
    path: "/fredit",
    ele: <Fredit />,
    permissions: [],
    icon: <MultipleStopIcon />,
    isMenu: true,
  },
  {
    name: "Support",
    path: "/support",
    ele: <Support />,
    permissions: ["MERCHANT"],
    icon: <SupportAgentOutlinedIcon />,
    isMenu: true,
  },
  {
    name: "Chat",
    path: "https://brangchain-org.myfreshworks.com/crm/messaging/a/858372773098179/inbox/0/0/conversation/936081189821085",
    ele: null,
    permissions: ["ADMIN"],
    icon: <ChatBubbleIcon />,
    isMenu: true,
    isDirectRedirect: true,
    target: "_blank",
  },
];

const RouteWrapper = () => {
  const userType = useSelector((state) => state.auth.userType);
  const userObject = useSelector((state) => state.auth.userObject);

  // useEffect(() => {
  //   // Create a script element
  //   const script = document.createElement("script");
  //   script.src = "https://chat.hippochat.io/js/widget.js";
  //   script.async = true;
  //   script.id = "hippo-widget-script";

  //   // Define an onload handler for the script
  //   const onLoadHandler = () => {
  //     if (userType === "MERCHANT") {
  //       const divWithMargin = document.getElementById("main_box");
  //       if (divWithMargin) {
  //         divWithMargin.style.marginBottom = "60px"; // Adjust the margin value as needed
  //       }
  //       window.initHippo({
  //         appSecretKey: "66d0d316a5c53e653624396e478b341a",
  //         botGroupID: "3000",
  //         language: "en",
  //         color: "#696cff",
  //         uniqueId: userObject?.id ? userObject?.id : null,
  //         email: userObject?.email ? userObject?.email : null,
  //         name: userObject?.first_name ? userObject?.first_name : null,
  //         phone: userObject?.phone ? userObject?.phone : null,
  //       });
  //     } else {
  //       // Get a reference to the iframe element by its ID
  //       var iframeToRemove = document.getElementById("iframe_fuguWidget");

  //       // Check if the iframe element exists
  //       if (iframeToRemove) {
  //         // Remove the iframe from the DOM
  //         iframeToRemove.remove();
  //       }
  //       window.destroyHippoCustomerWidget();
  //     }
  //   };

  //   // Add the onload handler
  //   script.onload = onLoadHandler;

  //   // Append the script to the body
  //   document.body.appendChild(script);

  //   // Define a cleanup function for when the component is unmounted
  //   return () => {
  //     // Remove the script element from the body
  //     document.body.removeChild(script);

  //     // Cleanup any additional resources if needed
  //   };
  // }, [userType]);

  return (
    <Box>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        {/* <Route path="/logout" element={<Logout />} /> */}
        <Route path="/change_password/:id" element={<ResetPassword />} />
        <Route path="/tracking/:hash" element={<TrackingLayout />} />
        {/* <Route path="/live-tracking/:hash" element={<TrackingLayout />} /> */}
        {routes?.length > 0 &&
          routes.map((route, index) => {
            if (route.isRedirectLink) {
              return
            } else {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <PrivateRoute permissions={route.permissions}>
                      {route.ele}
                    </PrivateRoute>
                  }
                />
              );
            }
          })}
        ;{/*/!* ADMIN ROUTES *!/*/}
        {/*<Route path="/brangers" element={<PrivateRoute role="ADMIN"><Brangers /></PrivateRoute>} />*/}
        {/*/!* MERCHANT ROUTES *!/*/}
        {/*<Route path="/wallet" element={<PrivateRoute role="MERCHANT"><Wallet /></PrivateRoute>} />*/}
        {/*/!* COMMON ROUTES *!/*/}
        {/*<Route path="/dashboard" element={<PrivateRoute role="COMMON"><Dashboard /></PrivateRoute>} />*/}
        {/*<Route path="/profile" element={<PrivateRoute role="COMMON"><Profile /></PrivateRoute>} />*/}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Box>
  );
};

export default RouteWrapper;
