import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUserObject, setUser } from '../../store/auth/authSlice';
import { usePutUsersMutation } from '../../store/users/usersApiSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Box, Button, Typography } from '@mui/material';
import FAQ from './FAQ';
import "./style.css";
import NeedMoreHelp from './NeedMoreHelp';
import { mode } from 'crypto-js';

const Support = () => {
    const user = useSelector(selectCurrentUserObject);
    const dispatch = useDispatch();
    const [putUsers, { isLoading: isPutUserLoading }] = usePutUsersMutation();

    console.log(user, "user");
    const updateUser = async (restoreId) => {
        const formdata = new FormData();
        formdata.append("freshworks_chat_id", restoreId);
        formdata.append("user_id", user?.id);

        try {
            const response = await putUsers(formdata).unwrap().then((res) => {
                console.log("call");

                if (res.data) {
                    dispatch(setUser(res.data));
                }
            }).catch((err) => {
                if (err) {
                    toast.error(`Something went wrong!`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
        } catch (error) {
            toast.error(`${error}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            console.log(error);
        }
    }

    // Function to handle Freshchat events
    const freshChatEvents = () => {
        window.fcWidget.setTags(["premium", "paid"]);
        if (user?.freshworks_chat_id) {
            window.fcWidget.user.setProperties({
                external_id: user?.id,
                restore_id: user?.freshworks_chat_id,
                first_name: user?.first_name,
                email: user?.email,
            });
        } else {
            window.fcWidget.on('user:created', (resp) => {
                const status = resp && resp.status;
                const data = resp && resp.data;
                console.log("user:created******");

                if (status === 200 && data?.restoreId) {
                    if (data.restoreId) {
                        updateUser(data.restoreId);
                    }
                }
            });
        }
    };

    // Function to initialize the widget
    const initWidget = (fcWidgetMessengerConfig) => {
        window.fcWidgetMessengerConfig = {
            ...fcWidgetMessengerConfig, config: {
                headerProperty: {
                    hideChatButton: true,
                },
                fullscreen: true,
                // Custom Theme for Dark Mode
                theme: {
                    primaryColor: '#000',   // White color for buttons or icons
                    secondaryColor: '#333', // Dark background for chat
                    backgroundColor: '#222', // Dark background for widget
                    buttonColor: '#555',     // Dark color for buttons
                    textColor: '#ccc'        // Light text color for readability
                }
            },
        };

        const freshchatScript = document.createElement('script');
        freshchatScript.src = '//in.fw-cdn.com/31816985/941709.js';
        freshchatScript.setAttribute('chat', 'true');
        freshchatScript.id = 'freshchatScript'; // Assign an id to the script

        freshchatScript.onload = () => {
            window.fwcrm.on('widget:loaded', freshChatEvents);
        };

        const head = document.head || document.getElementsByTagName('head')[0];
        head.insertBefore(freshchatScript, head.firstChild);
    };

    // Function to initialize FreshChat widget
    const initializeFreshChatWidget = () => {
        initWidget({
            externalId: user?.id,
            restoreId: user?.freshworks_chat_id || null,
            firstName: user?.first_name,
            tags: ["premium", "paid"],
            email: user?.email,
        });
    };

    // Function to open the FreshChat widget on button click
    const openFreshChat = () => {
        if (window.fcWidget) {
            window.fcWidget.open(); // This will open the FreshChat widget
        }
    };

    // Run `initializeFreshChatWidget` when the component mounts
    useEffect(() => {
        initializeFreshChatWidget();

        return () => {
            // Cleanup when the component is unmounted or when the page changes
            const scriptToRemove = document.getElementById('freshchatScript');
            if (scriptToRemove) {
                scriptToRemove.remove();
            }

            // Destroy the widget when the page is changing or component is unmounted
            if (window.fcWidget) {
                window.fcWidget.destroy();
                console.log("FreshChat widget destroyed on page change.");
            }
        };
    }, []); // Empty dependency array ensures this effect runs only on mount and unmount

    return (
        <Box sx={{ padding: { xl: "0 104px", lg: "0 104px", md: "0 104px", sm: "0 0", xs: "0 0" }, marginTop: "54px", marginBottom: "54px", }}>
            <Box className="faq_section">
                <Box className="faq_content">
                    <Typography>FAQs</Typography>
                    <Typography variant="h2">Frequently Asked Questions</Typography>
                    <Typography variant="h6">Have questions? We’re here to help</Typography>
                </Box>
            </Box>
            <FAQ />
            <Box>
                <NeedMoreHelp onClickChat={openFreshChat} />
            </Box>
        </Box>
    );
};

export default Support;
